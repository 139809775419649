import React, { useState } from 'react'
import ReactModal from '.';
import { useSelector } from 'react-redux';
import Loader from '../components/custom/loader';
import Error from '../components/custom/error';
import Button from '../components/custom/button';
import Label from '../components/custom/label';
import { TbReportAnalytics } from "react-icons/tb";
import Dropdown from '../components/custom/dropdown';

const ReportGenerator = () => {
    const { projects } = useSelector((state) => state.project);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState("");
    const [ disabled, setDisabled ] = useState(false);
    const [ selectedProject, setSelectedProject ] = useState(null);

    const reportGenerator = async () => {
        setError("");
        setDisabled(true);
        if (!selectedProject){
            setError("Please select project");
            setDisabled(false);
        }else{
            try {
                const response = await fetch(`${process.env.REACT_APP_URL}/log/project/${selectedProject._id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/csv',
                    },
                });
        
                if (!response.ok) {
                    setError("Report generation failed")
                    setDisabled(false);
                }
        
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'project-report.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a); // Clean up after download
                window.URL.revokeObjectURL(url); // Free up the memory
                setDisabled(false)
            } catch (error) {
                setError("Failed to download CSV")
                setDisabled(false);
            }
        }
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            reportGenerator()
        }
    };

    function closeHandler(){
        setOpen(false);
        setError("");
        setDisabled(false);
    }

    return (
        <>
            <button title='Download Report' onClick={() => setOpen(true)} className="h-[35px] w-[35px] rounded-full flex items-center justify-center bg-white shadow-md">
                <TbReportAnalytics color="#000" size={20} />
            </button>
            <ReactModal open={open} close={closeHandler} maxWidth='600px' heading='Generate Report' padding='20px' >
                {disabled && <Loader />}
                <Error message={error} className='mb-[5px]' />
                <div className="w-full">
                    <Label title='Select Project' />
                    <Dropdown
                        value={selectedProject ? selectedProject.title : ""}
                        onChange={(option) => {
                            setSelectedProject(option);
                        }}
                        options={projects}
                        onKeyDown={handleKeyDown}
                        className='mt-[5px]'
                    />
                </div>
                <div className='text-center mt-[40px]'>
                    <Button
                        label='Generate'
                        onClick={reportGenerator}
                    />
                </div>
            </ReactModal>
        </>
    )
}

export default ReportGenerator;