import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { PutRequest } from '../../utils/request';
import { GiFox } from 'react-icons/gi';
import ChatSocket from "../../socket/chat-socket";
import { chatActions } from '../../store/chat-slice';
import { Link, useLocation, useParams } from 'react-router-dom';
import Avatar from '../custom/avatar';

const RecentChat = ({ getRequest = () => { }, ...props }) => {
    const { conversationId } = useParams();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { currentChat, chatList, triggerChat } = useSelector((state) => state.chat);
    const { accountInfo, meOnline } = useSelector((state) => state.account);

    useEffect(() => {
        if (currentChat && currentChat.convoId && meOnline && accountInfo && (pathname === "/chat" || pathname === `/chat/${conversationId}`)) {
            PutRequest(`${process.env.REACT_APP_URL}/message/seen/${currentChat.convoId}?userId=${accountInfo?.data._id}`).then((response) => {
                ChatSocket.emitOpenChat(currentChat?.userData?._id, accountInfo?.data?._id);
                dispatch(chatActions.setTriggerChat(triggerChat + 1))
            }).catch((error) => {
                console.log("error seeing messages", error)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [meOnline, accountInfo, currentChat])

    function handleOpenChat(friend) {
        dispatch(chatActions.setCurrentChat(friend))
        PutRequest(`${process.env.REACT_APP_URL}/message/seen/${friend.convoId}?userId=${accountInfo?.data._id}`).then((response) => {
            ChatSocket.emitOpenChat(friend?.userData._id, accountInfo?.data?._id);
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
        }).catch((error) => {
            console.log("error seeing messages", error)
        })
    }
    return (
        <div className='px-[10px]'>
            <div className='text-[12px] text-[#aaa] text-left sticky left-0 right-0 z-[9] w-full py-[5px]'>Recent Chats</div>
            <div className='overflow-y-auto max-h-[30vh] pb-[10px]'>
                {chatList.length > 0 ? (
                    <div className='grid grid-cols-3 gap-[10px] items-start mt-[10px]'>
                        {chatList.map((item, i) => (
                            item.participants.length > 1 ? (
                                <Link key={i} to={`/chat/${item._id}`}>
                                    <Avatar
                                        className='w-[45px] h-[45px] mx-auto'
                                        isOnline
                                        isGroup
                                        // onClick={() => dispatch(chatActions.setCurrentChat(item?._id))}
                                    />
                                    <div className='text-center text-wrap'>Group</div>
                                </Link>
                            ) : (
                                <Link key={i} to={`/chat/${item._id}`} {...props} className='relative'>
                                    {item.unread > 0 && !item?.blockedBy?.length > 0 && (
                                        <span className='absolute right-[10px] top-[-5px] bg-white border rounded-full text-[12px] z-[99] px-[3px]'>{item.unread}</span>
                                    )}
                                    <Avatar
                                        id={item.participants[0]?._id}
                                        src={item
                                            ? item?.blockedBy?.includes(item.participants[0]?._id)
                                                ? ""
                                                : item?.settings
                                                    ? item?.settings.showAvatar
                                                        ? item.participants[0]?.avatar
                                                        : ""
                                                    : item.participants[0]?.avatar
                                            : ""
                                        }
                                        className={`w-[45px] h-[45px] mx-auto ${( (pathname === "/chat" || pathname === `/chat/${conversationId}`) && currentChat?.userData?._id === item?.participants[0]?._id) ? "!border-[2px] border-primary" : "border-[#aaa]"}`}
                                        onClick={() => {
                                            dispatch(chatActions.setLoadingChat(true))
                                            handleOpenChat({
                                                convoId: item._id,
                                                userData: item.participants[0],
                                                isFriend: item.isFriend,
                                                blockedBy: item.blockedBy,
                                                settings: item.settings
                                            })
                                            getRequest(item._id);
                                            dispatch(chatActions.setCurrentPage(1))
                                            dispatch(chatActions.setAllMessages([]))
                                            dispatch(chatActions.setWasLastList(false))
                                        }}
                                        isFriend={item?.isFriend}
                                        chatList={item}
                                    />
                                    <div className={`text-center text-default text-wrap text-[12px] capitalize ${ (pathname === "/chat" || pathname === `/chat/${conversationId}`) && currentChat?.userData?._id === item?.participants[0]?._id && "font-semibold"}`}>
                                    {item?.participants[0]?.fullName ? item?.participants[0]?.fullName.length > 15 
                                        ? `${item?.participants[0]?.fullName.slice(0, 15)}...` 
                                        : item?.participants[0]?.fullName
                                    : "Deleted User"}
                                    </div>
                                </Link>
                            )
                        ))}
                    </div>
                ) : (
                    <div className='flex flex-col items-center justify-center flex-grow h-full'>
                        <GiFox size={30} color='#C15817' />
                        <div className='text-center text-default text-wrap mt-[10px]'>Quiet <br /> Coyote</div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RecentChat;