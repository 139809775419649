import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

export const decrypt = (data) => {
    if (data?.length > 0) {
        const decryptedData = CryptoJS.AES.decrypt(data, secretKey).toString(CryptoJS.enc.Utf8);
        return decryptedData;
    } else {
        return "";
    }
};

export const encrypt = (data) => {
    if (data?.length > 0) {
        const ciphertext = CryptoJS.AES.encrypt(data, secretKey);
        return ciphertext.toString();
    } else {
        return "";
    }
};