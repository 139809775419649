import React, { useEffect, useState } from 'react';
import Department from '../../../popups/department';
import Designation from '../../../popups/designation'
import { useDispatch, useSelector } from 'react-redux';
import { GetRequest } from '../../../utils/request';
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import isBirthdayToday from '../../../utils/isBirthdayToday';
import IconButton from '../../../components/custom/iconButton';
import Search from '../../../components/custom/search';
import Layout from '../../../layout';
import { employeeActions } from '../../../store/employee-slice';
import DepartmentUpdate from '../../../popups/departmentUpdate';
import Pagination from '../../../components/custom/pagination';
import EmployeeFilter from '../../../components/employee/filter';
import { uiActions } from '../../../store/ui-slice';
import EmployeeCard from '../../../components/employee/card';
import { useNavigate } from 'react-router-dom';
import EmployeeAlert from '../../../popups/employeeAlert';

const Employees = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { accountInfo } = useSelector((state) => state.account);
    const {
        employees,
        filteredResults,
        departments,
        roles,
        selectedDepartment,
        selectedRole,
        searchKey,
        employeeTrigger,
        pageCount,
        totalEmployeesCount
    } = useSelector((state) => state.employee);
    const [loader, setLoader] = useState(true);
    const [searchBirthday, setSearchBirthday] = useState(false);
    const [departmentTrigger, setDepartmentTrigger] = useState(0);
    const [roleTrigger, setRoleTrigger] = useState(0)
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selected, setSelected] = useState({
        department: null
    })
    const permissions = accountInfo?.permissions;
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data.adminId || accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, departmentTrigger])

    useEffect(() => {
        if (selectedDepartment && selectedDepartment._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${selectedDepartment._id}`).then(response => {
                dispatch(employeeActions.setRoles(response.data));
                dispatch(employeeActions.setSelectedRole(null));
            }).catch((error) => {
                console.log("roles error >", error);
                dispatch(employeeActions.setRoles([]));
            });
        } else {
            dispatch(employeeActions.setRoles([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartment, roleTrigger]);

    useEffect(() => {
        if (accountInfo) {
            getEmployees(pageCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, employeeTrigger])

    const getEmployees = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/user/employees/${accountInfo.data.adminId || accountInfo.data._id}?page=${page}&limit=30`).then(response => {
            dispatch(employeeActions.setEmployees(response.data.data))
            dispatch(employeeActions.setFilteredResults(response.data.data))
            dispatch(employeeActions.setTotalEmployeesCount(response.data.totalEmpoyeesCount))
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            console.log(" employee error >", error);
        })
    }

    // FILTER DATA THROUGH SEARCH BAR AND KEY
    useEffect(() => {
        if (searchKey.length > 0) {
            const filtered = employees.filter(index => {
                return index.fullName?.toLowerCase().includes(searchKey?.toLowerCase()) || index.email?.toLowerCase().includes(searchKey?.toLowerCase())
            })
            dispatch(employeeActions.setFilteredResults(filtered))
        } else {
            dispatch(employeeActions.setFilteredResults(employees))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees, searchKey]);

    useEffect(() => {
        if (selectedRole) {
            const filtered = employees.filter(index => {
                return index.role?._id === selectedRole?._id;
            })
            dispatch(employeeActions.setFilteredResults(filtered))
        } else if (selectedDepartment) {
            const filtered = employees.filter(index => {
                return index.role?.departmentId?._id === selectedDepartment?._id;
            });
            dispatch(employeeActions.setFilteredResults(filtered))
        } else {
            dispatch(employeeActions.setFilteredResults(employees))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees, selectedRole, selectedDepartment]);

    useEffect(() => {
        if (searchBirthday) {
            const filtered = employees.filter(index => {
                return isBirthdayToday(index.birthday);
            })
            dispatch(employeeActions.setFilteredResults(filtered))
        } else {
            dispatch(employeeActions.setFilteredResults(employees))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchBirthday, employees]);

    const handleAddEmployee = () => {
        if (departments && departments.length > 0) {
            if ((isAdmin || accountInfo?.permissions?.addEmployee) && departments.length > 0) {
                navigate('/employees/add')
            }
        } else {
            setModalOpen(true)
        }

    }

    return (
        <Layout>
            {(isAdmin || permissions?.addEmployee) && (
                <div className='flex max-md:flex-wrap pt-[30px] lg:pt-[50px]'>
                    <Department label="Add Department" className='border h-[60px] w-full rounded-r-full mr-[-20px] relative bg-[#fff0f5] z-[3]' setTrigger={setDepartmentTrigger} />
                    <Designation label="Add Designation" className='border h-[60px] w-full rounded-r-full mr-[-20px] relative bg-white z-[2]' setTrigger={setRoleTrigger} />
                    <button
                        className={`flex border h-[60px] w-full rounded-r-full mr-[-20px] relative bg-[#f5f5dc] z-[1] items-center justify-center ${!(isAdmin || accountInfo?.permissions?.addEmployee)
                            ? 'pointer-events-none opacity-50 cursor-not-allowed'
                            : 'cursor-pointer'
                            }`}
                        onClick={handleAddEmployee}
                    >
                        Add Employee
                    </button>

                </div>
            )}

            <DepartmentUpdate
                data={selected.department}
                open={open}
                close={() => setOpen(false)}
                setSelected={setSelected}
                setDepartmentTrigger={setDepartmentTrigger}
                roles={roles}
                setRoleTrigger={setRoleTrigger}
            />

            <div className='mt-[50px] mb-[50px]'>

                <EmployeeFilter
                    departments={departments}
                    roles={roles}
                    selectedRole={selectedRole}
                    selectedDepartment={selectedDepartment}
                    employees={employees}
                    setOpen={setOpen}
                    setSelected={setSelected}
                    setSearchBirthday={setSearchBirthday}
                />

                <div className='flex gap-[10px] flex-col-reverse lg:flex-row justify-between item-center mt-[10px] mb-[30px]'>
                    <div className='flex gap-[10px]'>
                        <h3 className='my-auto'>Employees</h3>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center gap-[30px]'>
                <button
                    onClick={() => {
                        setSearchBirthday(prev => !prev);
                        dispatch(uiActions.setPaginationOffset(0));
                    }}
                    className={`w-[80px] border bg-white rounded-full flex ${searchBirthday ? "justify-end" : "justify-start"}`}
                >
                    <IconButton
                        icon={<LiaBirthdayCakeSolid size={20} color={searchBirthday ? "#880000" : "#aaa"} />}
                        className={`h-[40px] w-[40px] ${searchBirthday ? "bg-[#fff0f5]" : "bg-hover"}`}
                    />
                </button>
                <Search
                    placeholder='I will help you find... :)'
                    value={searchKey}
                    onChange={(e) => {
                        dispatch(employeeActions.setSearchKey(e.target.value))
                        dispatch(uiActions.setPaginationOffset(0))
                    }}
                    className='max-w-[250px]'
                />
            </div>
            {loader ? (
                <div className='text-center mt-[30px]'>Loading...</div>
            ) : (
                filteredResults.length > 0 ? (
                    <div className='mb-[50px]'>
                        <Pagination
                            itemsPerPage={30}
                            data={filteredResults}
                            renderComponent={(currentItems) => (
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[10px] mt-[30px]">
                                    {currentItems.map((item, i) => (
                                        <EmployeeCard data={item} key={i} />
                                    ))}
                                </div>
                            )}
                            onPageChange={getEmployees}
                            totalCount={totalEmployeesCount}
                        />
                    </div>
                ) : (
                    searchKey.length > 0 ? (
                        <div className='mt-[30px] text-center'>No Employee found!</div>
                    ) : (
                        <div className='mt-[30px] text-center'>{searchBirthday ? "No Birthday Today!" : "No Employees added!"}</div>
                    )
                )
            )}
            <EmployeeAlert open={modalOpen} setOpen={setModalOpen} />

        </Layout>
    );
};

export default Employees;