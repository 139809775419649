import React, { useEffect, useState } from 'react';
import ReactModal from '.';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../store/chat-slice';
import { GetRequest, PostRequest } from '../utils/request';
import Avatar from '../components/custom/avatar';
import Button from '../components/custom/button';
import { IoShareSocialSharp } from "react-icons/io5";
import socket from '../socket';

const ForwardNote = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { triggerChat } = useSelector((state) => state.chat);
    const { accountInfo } = useSelector((state) => state.account);
    const [conversationList, setConversationList] = useState([]);
    const [sentList, setSentList] = useState([]);

    function closeHandler() {
        setOpen(false);
        dispatch(chatActions.setForwardData(props));
        setSentList([]);
    }

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/conversation/${accountInfo?.data?._id}`).then(response => {
                setConversationList(response.data);
            }).catch(error => {
                console.log("conversation error >>>", error?.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, triggerChat]);

    function messageForward(id) {
        PostRequest(`${process.env.REACT_APP_URL}/notes/forward`, {
            user: id,
            forwardedBy: accountInfo?.data?._id,
            color: props?.color,
            note: props?.note
        }).then(response => {
            socket.emit("note shared", response.data)
            setSentList(prevList => [...prevList, id])
        }).catch(error => {
            console.log("note error >>>", error);
        });
    }
    // Filter conversation list
    const filteredConversations = conversationList.filter(item => {
        const isBlocked = item.blockedBy?.includes(accountInfo?.data?._id);
        const isFriend = item.isFriend === true;
        return !isBlocked && isFriend;
    });

    return (
        <>
            <button onClick={() => setOpen(true)}
                className='bg-[#a8a8a8] text-[#fff] text-center rounded-full p-2'>
                <IoShareSocialSharp size={18} />
            </button>

            <ReactModal open={open} close={closeHandler} maxWidth="500px" heading="Forward Note" padding='20px'>
                <div className={`${filteredConversations.length > 0 && 'scroll-it overflow-hidden overflow-y-scroll'} px-[10px] max-h-[300px] `}>
                    {conversationList.length > 0 ? (
                        filteredConversations.length > 0 ? (
                            <div className='grid gap-[10px] w-full'>
                                {filteredConversations.map((item, i) => (
                                    <div key={i} className='flex items-center justify-between gap-[10px] w-full'>
                                        <div className='flex items-center justify-start gap-[10px] w-full'>
                                            <Avatar
                                                src={item
                                                    ? item?.blockedBy?.includes(item.participants[0]?._id)
                                                        ? ""
                                                        : item?.settings
                                                            ? item?.settings.showAvatar
                                                                ? item.participants[0]?.avatar
                                                                : ""
                                                            : item.participants[0]?.avatar
                                                    : ""
                                                }
                                                className='w-[45px] h-[45px] cursor-default'
                                                noOnline
                                            />
                                            <div className='text-left text-wrap'>{item?.participants[0]?.fullName}</div>
                                        </div>
                                        <button
                                            onClick={() => messageForward(item?.participants[0]?._id, item?._id)}
                                            className={` rounded-full px-[20px] py-[5px] ${sentList.includes(item?.participants[0]?._id) ? "bg-white text-pink-600 border border-[#ff4081] pointer-events-none" : "bg-strawberry text-white"}`}
                                        >
                                            {sentList.includes(item?.participants[0]?._id) ? "Sent" : "Send"}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className='text-center mt-[30px]'>No friends!</p>
                        )
                    ) : (
                        <p className='text-center mt-[30px]'>Loading...</p>
                    )}
                </div>
                <div className='mt-[30px]'>
                    <Button
                        label='Done'
                        type='secondary'
                        onClick={closeHandler}
                    />
                </div>
            </ReactModal>
        </>
    );
}

export default ForwardNote;
