import React, { useEffect, useState } from 'react';
import { GetRequest, PostRequest } from '../utils/request';
import Error from '../components/custom/error';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { uiActions } from '../store/ui-slice';
import Header from '../layout/header';
import Footer from '../layout/footer';
import { WindowScroll } from '../utils/windowScroll';
import PersonalInfo from '../components/registration/personalInfo';
import StepProgressBar from '../components/custom/stepProgressBar';
import Verification from '../components/registration/verification';
import CompanyInfo from '../components/registration/companyInfo';
import PlanSelection from '../components/registration/planSelection';
import Password from '../components/registration/password';
import Loader from '../components/custom/loader';
import { validateEmail, validatePassword, validatePhone } from '../utils/formValidation';

const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formInput, setFormInput] = useState({
        fullName: "",
        email: "",
        mobile: "",
        stdCode: "+91",
        companyTitle: "",
        companyAddress: "",
        password: "",
        confirmPassword: "",
        plan: ""
    })
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState()
    const [page, setPage] = useState(1);
    const [isVerified, setIsVerified] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpToken, setOtpToken] = useState('')

    const [showResendOtpMessage, setShowResendOtpMessage] = useState(false); 

    useEffect(() => {
        let otpTimer;
        if (page === 2) {  
            otpTimer = setTimeout(() => {
                if (!otp) {  
                    setShowResendOtpMessage(true);
                }
            }, 30000);
        }

        return () => clearTimeout(otpTimer); 
    }, [page, otp]);

    //SHOWS MESSAGE ON SUCCESSFULL REGISTRATION
    useEffect(() => {
        if (success) {
            dispatch(uiActions.setToastify({
                message: "Registered Successfully!",
                isSuccess: true
            }));
            setSuccess(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success]);

    //FETCHES ALL PLANS
    useEffect(() => {
        GetRequest(`${process.env.REACT_APP_URL}/plan`).then(response => {
            setPlans(response.data);
            setFormInput((prevState) => ({ ...prevState, plan: response.data[0]?._id }));
        }).catch(error => {
            console.log("error >", error);
        })
    }, [])

    function onFirstClick() {
        setLoading(true)
        setError("")
        if (!formInput.fullName || !formInput.email || !formInput.mobile) {
            setError("Fields must not be empty!");
            setLoading(false)
        } else if (!validateEmail(formInput.email)) {
            setError("Email is invalid!");
            setLoading(false)
        } else if (!validatePhone(formInput.mobile)) {
            setError("Phone Number is invalid!");
            setLoading(false)
        } else {
            PostRequest(`${process.env.REACT_APP_URL}/account/`, {
                fullName: formInput.fullName,
                mobile: formInput.mobile,
                email: formInput.email
            }).then(response => {
                setLoading(false)
                dispatch(uiActions.setToastify({
                    message: "One time Password has been sent to your whatsapp!",
                    isSuccess: true
                }));
                setPage(2)
                setOtpToken(response.data)
            }).catch(err => {
                setLoading(false)
                setError(err.data || "Something went wrong!")
                console.log(err.data || "Something went wrong!");
            });
        }
    }

    function onSecondClick() {
        setLoading(true)
        setError("");
        if (isVerified) {
            setPage(3)
            setLoading(false)
        } else {
            if (!otp) {
                setError("Field must not be empty!");
                setLoading(false)
            } else {
                const config = {
                    headers: {
                        Authorization: `Bearer ${otpToken}`,
                    },
                }
                GetRequest(`${process.env.REACT_APP_URL}/account/verify?mobile=${formInput.mobile}&key=${otp}`, config).then(response => {
                    setLoading(false)
                    setIsVerified(true)
                    dispatch(uiActions.setToastify({
                        message: "OTP verified successfully!",
                        isSuccess: true
                    }));
                }).catch(err => {
                    setLoading(false)
                    setError(err.data || "Something went wrong!")
                    console.log(err.data || "Something went wrong!");
                });
            }
        }
    }

    function onThirdClick() {
        setLoading(true)
        setError("");
        if (!formInput.companyTitle || !formInput.companyAddress) {
            setError("Fields must not be empty!");
            setLoading(false)
        } else {
            setPage(4)
            setLoading(false)
        }
    }

    function onFourthClick() {
        setError("")
        setLoading(false)
        if (!formInput.plan) {
            setError("Select plan before proceeding");
            setLoading(false)
        } else {
            setPage(5)
            setLoading(false)
        }
    }

    function onCompleteClick() {
        setLoading(true)
        setError("")
        if (!formInput.password || !formInput.confirmPassword) {
            setError("Fields must not be empty!");
            setLoading(false)
        } else if (!validatePassword(formInput.password)) {
            setError("Password should contain atleast 8 characters and must contain one uppercase, one lowercase, one digit and one special character!");
            setLoading(false)
        } else if (formInput.password !== formInput.confirmPassword) {
            setError("Confirm Password should be same as Password!");
            setLoading(false)
        } else if (!isVerified) {
            setError("Verify your mobile first!");
            setLoading(false)
        } else {
            PostRequest(`${process.env.REACT_APP_URL}/account/save`, formInput).then(response => {
                setLoading(false)
                dispatch(uiActions.setToastify({
                    message: "Registered Successfully!",
                    isSuccess: true
                }));
                setPage(1)
                navigate("/login")
            }).catch(err => {
                setLoading(false)
                setError(err.data || "Registration failed!")
                console.log(err.data || "Registration failed!");
            });
        }
    }

        return (
        <>
            <Header />
            <div className="md:pt-[90px] md:pb-[20px] max-md:py-[50px] bg-[linear-gradient(0deg,#fff_0%,#c5eaf9_100%)]">
                <div className="container mx-auto md:px-[40px] max-md:px-[15px]">
                    <div className="max-w-[600px] px-[10px] md:px-[30px] py-[40px] relative bg-white mx-auto w-full rounded-[10px] border">
                        {loading && (
                            <Loader />
                        )}
                        <h2 className='mb-[20px] text-center md:text-left'>Sign Up</h2>
                        <StepProgressBar page={page}/>
                        <Error message={error} />
                        {
                            {
                                1:
                                    <PersonalInfo
                                        formInput={formInput}
                                        setFormInput={setFormInput}
                                        setError={setError}
                                        onNextClick={onFirstClick}
                                    />,
                                2:
                                    <Verification
                                        isVerified={isVerified}
                                        otp={otp}
                                        setOtp={setOtp}
                                        setError={setError}
                                        onNextClick={onSecondClick}
                                        setPage={setPage}
                                        showResendOtpMessage={showResendOtpMessage}
                                        setShowResendOtpMessage={setShowResendOtpMessage}
                                    />,
                                3:
                                    <CompanyInfo
                                        formInput={formInput}
                                        setFormInput={setFormInput}
                                        setError={setError}
                                        setPage={setPage}
                                        setLoading={setLoading}
                                        onNextClick={onThirdClick}
                                    />,
                                4:
                                    <PlanSelection
                                        formInput={formInput}
                                        setFormInput={setFormInput}
                                        plans={plans}
                                        onNextClick={onFourthClick}
                                        setError={setError}
                                    />,
                                5:
                                    <Password
                                        formInput={formInput}
                                        setFormInput={setFormInput}
                                        setError={setError}
                                        onNextClick={onCompleteClick}
                                    />,
                            }[page]
                        }
                        <p className='text-center md:text-left mt-[10px]'>Already have an account?{' '}
                            <Link to="/login" onClick={WindowScroll} className='text-[#00f]'>Log In</Link>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Registration;