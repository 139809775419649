import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allNotes: [],
    trigger: 0,
    pageCount: 1,
    wasLastList: false,
    totalNotesCount: 0
}

const noteSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setAllNotes(state, action) {
            state.allNotes = action.payload;
        },
        setTrigger(state, action) {
            state.trigger = action.payload;
        },
        setPageCount(state, action) {
            state.pageCount = action.payload;
        },
        setWasLastList(state, action) {
            state.wasLastList = action.payload;
        },
        updateNote(state, action) {
            const { id, note, color } = action.payload;
            const index = state.allNotes.findIndex(n => n._id === id); // Assuming note has _id field
            if (index !== -1) {
                // Update the note at the found index
                state.allNotes[index] = {
                    ...state.allNotes[index],
                    note: note,
                    color: color
                };
            }
        },
        setTotalNotesCount(state, action) {
            state.totalNotesCount = action.payload;
        },
    }
});

export const noteActions = noteSlice.actions;

export default noteSlice.reducer;