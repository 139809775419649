import React, { useEffect, useState } from 'react'
import Layout from '../layout';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteRequest, GetRequest, PutRequest } from '../utils/request';
import Avatar from '../components/custom/avatar';
import { useDispatch, useSelector } from 'react-redux';
import { chatActions } from '../store/chat-slice';
import ChatSocket from '../socket/chat-socket';
import { uiActions } from '../store/ui-slice';
import Info from '../components/employee/info';
import Events from '../components/employee/events';
import Security from '../components/employee/security';
import Account from '../components/employee/account';
import { IoChatbubbleOutline } from 'react-icons/io5';
import { MdBlock, MdOutlineDeleteSweep } from 'react-icons/md';
import IconButton from '../components/custom/iconButton';
import Ratings from '../components/employee/ratings';
import { employeeActions } from '../store/employee-slice';

const Employee = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { employeeId } = useParams();
    const { currentChat, triggerChat } = useSelector((state) => state.chat);
    const { accountInfo } = useSelector((state) => state.account);
    const { employeeData } = useSelector((state) => state.employee);
    const [trigger, setTrigger] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const isAdmin = accountInfo?.data?.role?.title?.toLowerCase() === "admin";

    const deleteEmployee = accountInfo?.permissions?.deleteEmployee || false;
    const updateEmployee = accountInfo?.permissions?.updateEmployee || false;

    useEffect(() => {
        dispatch(chatActions.setLoadingChat(true))
        if (accountInfo && employeeId){
            GetRequest(`${process.env.REACT_APP_URL}/user/profile?userId=${accountInfo?.data?._id}&profileId=${employeeId}`).then(response => {
                dispatch(employeeActions.setEmployeeData(response.data));
                dispatch(chatActions.setCurrentChat(response.data));
                dispatch(chatActions.setLoadingChat(false))
            }).catch(error => {
                console.log("request accept error >>>", error.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[accountInfo, employeeId, trigger])

    function deleteChatHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/message/${currentChat?.convoId}`, {
            data: {
                deletedBy: accountInfo?.data?._id
            }
        }).then((response) => {
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            dispatch(chatActions.setAllMessages([]))
            dispatch(uiActions.setToastify({
                isSuccess: true,
                message: "Chat deleted successfully!"
            }))
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            console.log(error);
        });
    }

    function blockHandler() {
        PutRequest(`${process.env.REACT_APP_URL}/friend/block/${employeeData?.convoId}`, {
            blockedBy: accountInfo?.data?._id
        }).then((response) => {
            dispatch(chatActions.setCurrentChat({ ...employeeData, blockedBy: response.data }));
            dispatch(chatActions.setTriggerChat(triggerChat + 1))
            setTrigger(prev => prev + 1)
            ChatSocket.emitBlockUser(employeeData?.userData?._id, response.data, accountInfo?.data?._id)
        }).catch((error) => {
            console.log(error);
        });
    }

    const component = [
        <Info triggerEmployee={() => setTrigger(prev=> prev+1)} />,
        <Events />,
        <Security />,
        <Account triggerEmployee={() => setTrigger(prev=> prev+1)} />,
        <Ratings />
    ]

    const blockedByMe = employeeData?.blockedBy?.includes(accountInfo?.data?._id) || false;

    return (
        <Layout>
            <div className='mt-[30px] w-full flex justify-start px-[10px] relative'>
                {employeeData?.userData?._id !== accountInfo?.data?._id && (
                    <div className='absolute top-0 right-[10px] flex gap-[10px]'>
                        <IconButton 
                            icon={<IoChatbubbleOutline size={18} className="text-primary" />}
                            className='bg-white h-[30px] w-[30px]'
                            title="Chat Now"
                            onClick={() => {
                                if (!employeeData?.convoId){
                                    dispatch(chatActions.setCurrentChat({
                                        convoId: "", 
                                        userData: employeeData.userData, 
                                        isFriend: employeeData.isFriend || false,
                                        settings: employeeData.settings || null, 
                                        blockedBy: employeeData.blockedBy
                                    }))
                                    dispatch(chatActions.setAllMessages([]))
                                    dispatch(chatActions.setCurrentChatRequest(null))
                                    navigate("/chat")
                                }else{
                                    navigate(`/chat/${employeeData?.convoId}`)
                                }
                            }}
                        />
                        {/* <IconButton 
                            icon={<AiOutlineUserDelete size={18} className="text-primary" />}
                            className='bg-white h-[30px] w-[30px]'
                            title="Remove Friend"
                        /> */}
                        {employeeData?.convoId && (
                            <>
                            <IconButton 
                                icon={<MdOutlineDeleteSweep size={18} className="text-primary" />}
                                className='bg-white h-[30px] w-[30px]'
                                title="Delete Chat"
                                onClick={deleteChatHandler}
                            />
                            <IconButton 
                                icon={<MdBlock size={18} className={blockedByMe ? "text-success" : "text-error"} />}
                                className='bg-white h-[30px] w-[30px]'
                                title={blockedByMe ? "Unblock" : "Block"}
                                onClick={blockHandler}
                            />
                            </>
                        )}

                    </div>
                )}
        
                <div className='w-[150px] h-[150px] border items-center justify-center bg-white mb-[1px] hidden sm:inline-flex'>
                    {isAdmin ? (
                        <Avatar
                            src={employeeData?.userData?.avatar} 
                            alt={employeeData?.userData?.fullName}
                            noOnline
                            size={50}
                            className='!w-[120px] !h-[120px] object-center object-cover cursor-default'
                        />
                    ):(
                        <Avatar
                            src={employeeData
                                ? employeeData?.blockedBy?.includes(employeeData?.userData?._id)
                                    ? ""
                                    : employeeData?.settings
                                        ? employeeData?.settings.showAvatar
                                            ? employeeData?.userData.avatar
                                            : ""
                                        : employeeData?.userData?.avatar
                                : ""
                            }
                            alt={employeeData?.userData?.fullName}
                            noOnline
                            size={50}
                            className='!w-[120px] !h-[120px] object-center object-cover cursor-default'
                        />

                    )}
                </div>
                <div className='flex flex-col justify-between'>
                    <div className='px-[20px] pt-[10px]'>
                        {(isAdmin || deleteEmployee) && (
                            employeeData?.userData?.isActive ? (
                                <p className='text-success font-semibold'>Active</p>
                            ):(
                                <p className='text-error font-semibold'>Inactive</p>
                            )
                        )}
                        <h2 className='capitalize'>{employeeData?.userData?.fullName}</h2>
                        <p className='text-[#aaa]'>({employeeData?.userData?.email})</p>
                    </div>
                    <div className='flex mt-[10px] md:mt-[10px]'>
                        <button
                            className={`w-[70px] md:w-[100px] bg-transparent border-x border-t h-[35px] ${activeTab === 0 && "bg-white z-[1] relative border-[#000]"}`}
                            onClick={() => setActiveTab(0)}
                        >Info</button>
                        <button
                            className={`w-[70px] md:w-[100px] bg-transparent border-x border-t h-[35px] ${activeTab === 1 && "bg-white z-[1] relative border-[#000]"}`}
                            onClick={() => setActiveTab(1)}
                        >Events</button>
                        {(isAdmin || updateEmployee) && (
                            <button
                                className={`w-[70px] md:w-[100px] bg-transparent border-x border-t h-[35px] ${activeTab === 2 && "bg-white z-[1] relative border-[#000]"}`}
                                onClick={() => setActiveTab(2)}
                            >Security</button>
                        )}
                        {(isAdmin || deleteEmployee) && (
                            <button
                                className={`w-[70px] md:w-[100px] bg-transparent border-x border-t h-[35px] ${activeTab === 3 && "bg-white z-[1] relative border-[#000]"}`}
                                onClick={() => setActiveTab(3)}
                            >Account</button>
                        )}
                        <button
                            className={`w-[70px] md:w-[100px] bg-transparent border-x border-t h-[35px] ${activeTab === 4 && "bg-white z-[1] relative border-[#000]"}`}
                            onClick={() => setActiveTab(4)}
                        >Ratings</button>
                    </div>
                </div>
            </div>
            <div className='border-[#000] border-t rounded-t-[10px] mt-[-1px] bg-white shadow-md pb-[10px] mb-[30px]'>
                {component[activeTab]}
            </div>
        </Layout>
    )
}

export default Employee;