import React, { useEffect, useState } from 'react'
import Error from '../custom/error';
import Label from '../custom/label';
import TextInput from '../custom/textInput';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa6';
import Button from '../custom/button';
import { checkEmptyFields, validatePassword } from '../../utils/formValidation';
import { useDispatch, useSelector } from 'react-redux';
import { PutRequest } from '../../utils/request';
import { uiActions } from '../../store/ui-slice';

const Security = () => {
    const dispatch = useDispatch();
    const { employeeData } = useSelector((state) => state.employee);
    const { accountInfo } = useSelector((state) => state.account);
    const [formInput, setFormInput] = useState({
        newPass: "",
        confirmNewPass: ""
    })
    const [show, setShow] = useState({
        newPass: false,
        confirmNewPass: false
    });
    const [error, setError] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(false);
    
    function resetHandler(){
        setError("")
        setFormInput({
            newPass: "",
            confirmNewPass: "" 
        })
    }
    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    function updateHandler(){
        setDisabled(true);
        setError("")
        if (checkEmptyFields(formInput)){
            setError("Fields must not be empty!")
            setDisabled(false);
        }else if (!validatePassword(formInput.newPass)){
            setError("New Password must contain atleast one uppercase, one lowercase, one number and one special character!")
            setDisabled(false);
        }else if (formInput.newPass !== formInput.confirmNewPass){
            setError("Confirm password should match new password!")
            setDisabled(false);
        }else{
            PutRequest(`${process.env.REACT_APP_URL}/user/${employeeData?.userData?._id}?adminId=${accountInfo?.data._id}`, { password: formInput.newPass }).then((response) => {
                setDisabled(false)
                dispatch(uiActions.setToastify({
                    message: "Password updated successfully!",
                    isSuccess: true
                }))
                resetHandler()
            }).catch((error) => {
                console.log(error)
                setError(error.data)
                setDisabled(false)
            })
        }
    }

    useEffect(() => {
        if (success){
            const timer = setTimeout(() => {
                setSuccess(false);
                setDisabled(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    },[success])

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            updateHandler()
        }
    }; 

    return(
        <div className="bg-white px-[10px] p-[50px] md:p-[50px] my-[50px] max-w-[600px] mx-auto">
            <div>
                <Error message={error} />
                <div className='relative'>
                    <Label title='New Password' isImportant/>
                    <TextInput
                        name="newPass"
                        type={show.newPass ? "text" : "password"}
                        placeholder='********'
                        disabled={disabled}
                        value={formInput.newPass}
                        onChange={onChangeHandler}
                        onKeyDown={handleKeyDown}
                    />
                    {show.newPass ? (
                        <button onClick={() => setShow((prevState) => ({...prevState, newPass: false}))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                            <FaRegEyeSlash size={15} title="Hide Password" />
                        </button>
                    ) : (
                        <button onClick={() => setShow((prevState) => ({...prevState, newPass: true}))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                            <FaRegEye size={15} title="Show Password" />
                        </button>
                    )}
                </div>
                <div className='relative mt-[10px]'>
                    <Label title='Confirm New Password' isImportant/>
                    <TextInput
                        name="confirmNewPass"
                        type={show.confirmNewPass ? "text" : "password"}
                        placeholder='********'
                        value={formInput.confirmNewPass}
                        disabled={disabled}
                        onChange={onChangeHandler}
                        onKeyDown={handleKeyDown}
                    />
                    {show.confirmNewPass ? (
                        <button onClick={() => setShow((prevState) => ({...prevState, confirmNewPass: false}))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                            <FaRegEyeSlash size={15} title="Hide Password" />
                        </button>
                    ) : (
                        <button onClick={() => setShow((prevState) => ({...prevState, confirmNewPass: true}))} className='absolute right-[10px] top-[68%] translate-y-[-50%]' >
                            <FaRegEye size={15} title="Show Password" />
                        </button>
                    )}
                </div>
                <div className='mt-[20px] grid grid-cols-1 md:grid-cols-2 gap-[10px] md:gap-[20px]'>
                    <Button
                        label='Reset'
                        type='outlined'
                        className='bg-white border text-black'
                        disabled={disabled}
                        onClick={resetHandler}
                    />
                    <Button
                        label={success ? "Updated" : "Update Password"}
                        type='secondary'
                        onClick={updateHandler}
                        disabled={disabled}
                        className={success && "bg-success"}
                    />
                </div>
            </div>
        </div>
    )
}

export default Security