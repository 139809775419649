import React, { forwardRef } from 'react'
import DefaultLayout from "./default";
import { useSelector } from 'react-redux';
import SuperAdmin from './superAdmin';

const Layout = forwardRef(({children, containerClassName="", bgClass=""}, ref) => {
    const { accountInfo } = useSelector((state) => state.account);
    const role = accountInfo?.data?.role?.title?.toLowerCase();
    return (
        <div className='relative'>
            {role === "super admin" ? (
                <SuperAdmin>
                    {children}
                </SuperAdmin>
            ):(
                <DefaultLayout className={containerClassName} bgClass={bgClass} ref={ref}>
                    {children}
                </DefaultLayout>
            )}
        </div>
    )
})

export default Layout;