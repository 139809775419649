import React from 'react'
import CopyToClipboard from '../../utils/copyToClipboard';
import { IoIosRemoveCircleOutline, IoIosCheckboxOutline } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import { PutRequest } from '../../utils/request';
import { chatActions } from '../../store/chat-slice';
import ChatSocket from "../../socket/chat-socket";
import { FiEdit2 } from "react-icons/fi";
import { isTimeout } from '../../utils/formatTimestamp';
import { VscReply } from "react-icons/vsc";
import { GoCopy } from "react-icons/go";
import { LuForward } from 'react-icons/lu';

const MessageActions = ({ data, message = "", className = "", isMe = false}) => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { currentChat, openSelection } = useSelector((state) => state.chat);

    function afterCopyHandler() {
        dispatch(uiActions.setToastify({
            message: "Copied to Clipboard!",
            isSuccess: true
        }));
    }
    function removeHandler() {
        PutRequest(`${process.env.REACT_APP_URL}/message/remove/${data?._id}`).then((response) => {
            ChatSocket.emitRemoveMessage();
        }).catch((error) => {
            console.log("remove msg error", error);
        });
    }   
    
    const isFiveMinutesOut = data?.createdAt ? isTimeout(data?.createdAt) : false;

    return (
        <div className={`bg-white shadow-md p-[5px] absolute w-full right-0 min-w-[160px] z-[9999999] ${className}`}>
            <button onClick={() => CopyToClipboard(message, afterCopyHandler)} className='flex items-center px-[10px] py-[5px] bg-hover w-full'>
                <GoCopy size={18} className='mr-[10px]' /> Copy
            </button>
            {(!currentChat?.blockedBy?.includes(accountInfo?.data?._id) && !currentChat?.blockedBy?.includes(currentChat?.userData?._id)) && isMe && message !== "" && !isFiveMinutesOut && (
                <button         
                    onClick={() => {
                        dispatch(chatActions.setEditing(data?._id))
                        dispatch(chatActions.setMessage(message))
                    }}
                    className='flex items-center px-[10px] py-[5px] bg-hover w-full'
                ><FiEdit2 size={16} className='mr-[10px]' /> Edit</button>
            )}
            {!currentChat?.blockedBy?.includes(accountInfo?.data?._id) && message && (currentChat?.isFriend || isMe) && (
                <button
                    onClick={() => dispatch(chatActions.setReplyData(data))}
                    className='flex items-center px-[10px] py-[5px] bg-hover w-full'
                ><VscReply size={20} className='mr-[10px]' /> Reply</button>
            )}

            <button
                onClick={() => dispatch(chatActions.setForwardData([data]))}
                className='flex items-center px-[10px] py-[5px] bg-hover w-full'
            >
                <LuForward
                    size={20}
                    className='mr-[10px]'
                />
                Forward
            </button>

            {(!currentChat?.blockedBy?.includes(accountInfo?.data?._id) && !currentChat?.blockedBy?.includes(currentChat?.userData?._id)) && isMe && !isFiveMinutesOut && (
                <button onClick={removeHandler} className='flex items-center px-[10px] py-[5px] bg-hover w-full'>
                    <IoIosRemoveCircleOutline size={18} className='mr-[10px]' /> Remove
                </button>
            )}
            <button 
                onClick={() => {
                    dispatch(chatActions.setOpenSelection(!openSelection))
                    dispatch(chatActions.setMessage(""))
                } } 
                className='flex items-center px-[10px] py-[5px] bg-hover w-full'
            >
                <IoIosCheckboxOutline size={18} className='mr-[10px]' /> Select Messages
            </button>
        </div>
    )
}

export default MessageActions;