import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stickers from './stickers';
import OtherActions from './otherActions';
import Media from './media';
import ReplyMessage from './replyMessage';
import { chatActions } from '../../store/chat-slice';
import { GrFormClose } from "react-icons/gr";
import ChatSocket from "../../socket/chat-socket";
import { MdOutlineEmojiEmotions } from "react-icons/md";

const InputActions = ({
    setImage = () => { },
    setOpenEmoji = () => { },
    handleEmojiClick = () => { },
    messageHandler = () => { },
    handleIconClick = () => { },
    handleFileChange = () => { },
    handleKeyDown = () => { },
    ...props
}) => {
    const dispatch = useDispatch();
    const { replyData, message, editing, currentChat } = useSelector((state) => state.chat);
    const { accountInfo } = useSelector((state) => state.account);
    const [openSticker, setOpenSticker] = useState(false);

    const blockedMe = currentChat?.blockedBy?.includes(currentChat?.userData?._id) || false;

    const handleTyping = (e) => {
        const textarea = e.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 100)}px`;
        dispatch(chatActions.setMessage(e.target.value));

        if (!blockedMe) {
            ChatSocket.emitTypingIndicatorEvent(currentChat?.userData?._id, accountInfo?.data?._id);
        }
    };
    return (
        <div className='fixed right-0 bottom-0 bg-[#F5FFFA] w-full lg:pl-[250px]'>
            <div className='flex gap-[5px] md:gap-[10px] items-end justify-center py-[5px] max-w-[1100px] mx-auto px-[10px] md:px-[30px]'>
                <div className='relative overflow-hidden w-full'>
                    {/* OPENS EMOJI FOR INPUT */}
                    {/* <div className={`w-full h-full z-[30] ${props.openEmoji ? "block" : "hidden"}`} onMouseLeave={() => setOpenEmoji(false)}>
                        <EmojiPicker
                            onEmojiClick={handleEmojiClick}
                            emojiStyle="native"
                            style={{ width: "100%" }}
                            className='!rounded-b-none'
                        />
                    </div> */}
                    {/* <button onClick={() => setOpenEmoji(prev => !prev)} className='absolute bottom-[37px] left-[20px] z-[20]' title="Emoji">
                        <BsEmojiSmile size={20} />
                    </button> */}
                    {openSticker && (
                        <Stickers
                            setOpenSticker={setOpenSticker}
                        />
                    )}
                    {props.image && (
                        <Media
                            image={props.image}
                            setImage={setImage}
                        />
                    )}
                    {replyData && (
                        <ReplyMessage
                            replyData={replyData}
                        />
                    )}
                    <div className={`relative h-full bg-white w-full border pl-[50px] pr-[30px] focus:outline-none ${(props.image || openSticker || replyData) ? "!rounded-t-none rounded-b-[40px]" : "!rounded-[30px]"} ${props.openEmoji && "!rounded-t-none rounded-b-[40px]"}`}>

                        <button
                            onClick={() => alert("collecting beautiful stickers for you! ;)")}
                            className='absolute left-[15px] bottom-[15px]' title="Turn off editing"
                        >
                            <MdOutlineEmojiEmotions size={20} />
                        </button>
                        <textarea
                            ref={props.inputRef}
                            placeholder='Write message here...'
                            value={message}
                            maxLength={1000}
                            onChange={handleTyping}
                            onKeyDown={handleKeyDown}
                            className={`!scrollbar-w-1px h-full max-h-[300px] pt-[15px] resize-none bg-white w-full focus:outline-none overflow-y-auto leading-[1.1] text-[14px] block pb-[5px]`}
                        />
                        {editing && (
                            <button 
                                onClick={() => {
                                    dispatch(chatActions.setMessage(""))
                                    dispatch(chatActions.setEditing(null))
                                }} 
                                className='absolute right-[15px] bottom-[15px]' title="Turn off editing"
                            >
                                <GrFormClose size={25} />
                            </button>
                        )}
                    </div>
                </div>
                <OtherActions
                    messageHandler={messageHandler}
                    setOpenEmoji={setOpenEmoji}
                    setOpenSticker={setOpenSticker}
                    inputRef={props.inputRef}
                />
            </div>
        </div>
    )
}

export default InputActions;