import React, { useEffect, useState } from "react";
import Error from "../../components/custom/error";
import Pagination from "../../components/custom/pagination";
import Dropdown from "../../components/custom/dropdown";
import { GetRequest } from "../../utils/request";
import Button from "../custom/button";
import { validateEmail, validatePassword } from "../../utils/formValidation"
import { PostRequest } from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionActions } from "../../store/subscription-slice";
import { uiActions } from "../../store/ui-slice";

const BulkEmployeeUpload = ({ setOpenBulkUpload = () => { }, data = [], departments = [], setFile = () => { }, setFileError = () => { }, setSuccess = () => { }, setTrigger = () => { }, fileInputRef }) => {
   
    return (
        <div>
            <Pagination
                itemsPerPage={10}
                data={data}
                renderComponent={(currentItems) => (
                    <EmployeeData
                        data={currentItems}
                        departments={departments}
                        setFile={setFile}
                        setOpenBulkUpload={setOpenBulkUpload}
                        setFileError={setFileError}
                        setSuccess={setSuccess}
                        fileInputRef={fileInputRef}
                        setTrigger={setTrigger}
                    />
                )}
            />
        </div>
    )
}

function EmployeeData({ data, departments, setFile, setOpenBulkUpload, setFileError, setSuccess, fileInputRef, setTrigger }) {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { planTrigger } = useSelector((state) => state.subscription);


    const [selectedDepartments, setSelectedDepartments] = useState({});
    const [selectedRoles, setSelectedRoles] = useState({});
    const [roles, setRoles] = useState({});
    const [error, setError] = useState("");

    const convertDate = (date) => {
        const parsedDate = new Date(date);
        if (isNaN(parsedDate)) return null;
        return parsedDate.toLocaleDateString('en-CA');
    };

    useEffect(() => {
        const deptExist = data.filter((item) => item?.department && item.department !== "")
        if (deptExist) {
            deptExist.forEach((item, index) => {
                GetRequest(`${process.env.REACT_APP_URL}/department/roles/${item.department}`)
                    .then(response => {
                        setRoles(prev => ({
                            ...prev,
                            [index]: response.data
                        }));
                    })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDepartmentChange = (department, rowIndex) => {
        setSelectedDepartments(prev => ({
            ...prev,
            [rowIndex]: department
        }));

        if (department?._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${department._id}`)
                .then(response => {
                    setRoles(prev => ({
                        ...prev,
                        [rowIndex]: response.data
                    }));
                    setSelectedRoles(prev => ({
                        ...prev,
                        [rowIndex]: null
                    }));
                    setFile(prevFile => {
                        const updatedFile = [...prevFile];
                        updatedFile[rowIndex].department = department._id;
                        updatedFile[rowIndex].role = "";
                        return updatedFile;
                    });
                    setFileError('')

                })
                .catch(error => {
                    console.error("fetch roles error >", error);
                    setRoles(prev => ({
                        ...prev,
                        [rowIndex]: []
                    }));
                });
        } else {
            setRoles(prev => ({
                ...prev,
                [rowIndex]: []
            }));
            setFileError('')
        }
    };

    const handleRoleChange = (role, rowIndex) => {
        setSelectedRoles(prev => ({
            ...prev,
            [rowIndex]: role
        }));
        setFile(prevFile => {
            const updatedFile = [...prevFile];
            updatedFile[rowIndex].role = role._id;
            return updatedFile;
        });
        setFileError('')
    };

    const handleUpload = () => {
        setError("")
        if (data.length === 0) {
            setFileError("Add a valid CSV");
            return;
        }
        const missingDepartment = data.some((item) => !item.department);
        const missingRole = data.some((item) => !item.role);
        const invalidEmail = data.some((item) => !validateEmail(item.email));
        const invalidPassword = data.some((item) => !validatePassword(item.password));

        const datePattern = /^\d{2}[\/-]\d{2}[\/-]\d{4}$/;
        const invalidBirthday = data.some((item) => !datePattern.test(item.birthday));
        const invalidJoiningDate = data.some((item) => !datePattern.test(item.joiningDate));

        if (missingDepartment) {
            setFileError("Each employee must have a department selected.");
        } else if (missingRole) {
            setFileError("Each employee must have a role selected.");
        } else if (invalidEmail) {
            setFileError("Each employee's email must be valid.");
        } else if (invalidPassword) {
            setFileError("Each employee's password must be at least 8 characters and must contain one uppercase, one lowercase, one digit, and one special character!");
        } else if (invalidBirthday) {
            setError("Each employee's birthday must be in the format DD/MM/YYYY (e.g., 21/07/2024).");
        } else if (invalidJoiningDate) {
            setError("Each employee's joining date must be in the format DD/MM/YYYY (e.g., 21/07/2024).");
        } else {
            const updatedData = data.map((item) => ({
                ...item,
                birthday: item.birthday ? convertDate(item.birthday) : null,
                joiningDate: item.joiningDate ? convertDate(item.joiningDate) : null,
            }));

            PostRequest(`${process.env.REACT_APP_URL}/user/upload/${accountInfo.data._id}`, { data: updatedData })
                .then((response) => {
                    dispatch(uiActions.setToastify({
                        isSuccess: true,
                        message: "Employees file uploaded successfully"
                    }))
                    setFile([]);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                    setOpenBulkUpload(false)
                    setTrigger((prev) => prev + 1);
                    dispatch(subscriptionActions.setPlanTrigger(planTrigger + 1));
                })
                .catch((error) => {
                    console.error("Error uploading file:", error);
                    setFileError(error?.data || "Unknown error occurred");
                });
        }
    };

    return (
        <>
            {/* <div className="flex justify-end">
                <button className='w-[150px] border h-[40px] bg-strawberry text-white rounded-full' onClick={handleSave} >Done</button>
            </div> */}
            <div className="sm:rounded-lg max-md:pb-[10px]">
                {error && <Error message={error} />}

                <div className="overflow-x-auto scrollbar-h-5px">
                    <table className='mt-[20px] relative w-full'>
                        <thead>
                            <tr className='bg-[#dcdcdc] py-[120px]'>
                                {columns.map((col, i) => (
                                    <td
                                        key={i}
                                        style={{ width: col.width, textAlign: col.textAlign }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px] whitespace-nowrap'
                                    >{col.header}</td>
                                ))}
                                <td className='text-left whitespace-nowrap'></td>
                            </tr>
                        </thead>
                        {data.length > 0 ? (
                            <tbody>
                                {data.map((item, i) => (
                                    <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{i + 1 || "----"}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.employeeID || "----"}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.fullName || "----"}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.email}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.mobile}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            {item?.birthday}
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            {item?.joiningDate}
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.gender}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.password}</td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px]'>{item?.officeLocation}</td>
                                        <td className="text-left text-[14px] px-[10px] py-[5px]">
                                            <Dropdown
                                                value={
                                                    selectedDepartments[i]?.title ||
                                                    (departments?.find(dep => dep._id === item.department)?.title || "")
                                                }
                                                onChange={(option) => handleDepartmentChange(option, i)}
                                                options={departments}
                                                type="plain"
                                            />
                                        </td>
                                        <td className="text-left text-[14px] px-[10px] py-[5px]">
                                            <Dropdown
                                                value={
                                                    selectedRoles[i]?.title ||
                                                    (Object.keys(roles).length > 0 && roles[i]?.find(role => role._id === item.role)?.title) ||
                                                    ""
                                                }
                                                onChange={(option) => handleRoleChange(option, i)}
                                                options={roles[i] || []}
                                                type="plain"
                                                disabled={roles[i]?.length === 0}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <p className='text-center w-full absolute top-[70px]'>No Data Available!</p>
                        )}
                    </table>
                </div>
            </div>
            <div className='flex gap-4 justify-end '>
                <div className='flex  mt-[30px] w-[200px]'>
                    <Button
                        label='Reset'
                        onClick={() => {
                            setOpenBulkUpload(false);
                            setFile([]);
                            setFileError('')
                        }}
                        className='mt-[20px] '
                    />
                </div>
                <div className='flex justify-end mt-[30px] w-[200px]'>
                    <Button
                        label='Upload'
                        onClick={handleUpload}
                        type='secondary'
                        className='mt-[20px] max-w-[200px]'
                    />
                </div>
            </div>
        </>
    );
}

const columns = [
    {
        header: 'S.No.',
        textAlign: "left"
    },
    {
        header: "Employee ID",

        textAlign: "left"
    },
    {
        header: 'Full Name',

        textAlign: "left"
    },
    {
        header: 'Email',

        textAlign: "left"
    },
    {
        header: 'Mobile',

        textAlign: "left"
    },
    {
        header: 'Birthday',

        textAlign: "left"
    },
    {
        header: 'Date of Joining',

        textAlign: "left"
    },
    {
        header: 'Gender',

        textAlign: "left"
    },
    {
        header: 'Password',

        textAlign: "left"
    },
    {
        header: "Location",

        textAlign: "left"
    },
    {
        header: 'Department',
        width: "200px",
        textAlign: "left"
    }, {
        header: 'Designation',
        width: "200px",
        textAlign: "left"
    },
];

export default BulkEmployeeUpload;