import React from "react";
import ReactModal from ".";
import { LiaCatSolid } from "react-icons/lia";


const EmployeeAlert = ({ open = false, setOpen = () => { } }) => {
    
    function handleClose() {
        setOpen(false)
    }

    return (
        <ReactModal open={open} close={handleClose} maxWidth="600px" padding='50px' margin='10px' >
            <div className='text-center'>

                <LiaCatSolid className='mx-auto text-gray-700' size={60} />
                <h4 className='mt-4 text-lg font-semibold'>Oops! Cannot Add Employee!</h4>
                <p className='mt-2 text-sm text-gray-600'>
                    Add Department and Designation first.
                </p>
            </div>
        </ReactModal >
    )
};

export default EmployeeAlert;