import React from 'react'
import { FaBirthdayCake } from "react-icons/fa";
import { formatDate } from '../../utils/formatTimestamp';
import { FaAward } from "react-icons/fa";
import { useSelector } from 'react-redux';

const Events = () => {
    const { employeeData } = useSelector((state) => state.employee);
    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 py-[50px]'>
            <div className='p-[30px] mx-auto'>
                <FaBirthdayCake size={50} color='#FFA500' className='mx-auto' />
                <h2 className='text-center'>Birthday</h2>
                <p className='text-center'>{formatDate(employeeData?.userData?.birthday)}</p>
                <p className='text-center'>({numberOfYears(employeeData?.userData?.birthday)} years old)</p>
            </div>
            <div className='p-[30px] mx-auto'>
                <FaAward size={50} className='text-success mx-auto'/>
                <h2 className='text-center'>Work Anniversary</h2>
                <p className='text-center'>{formatDate(employeeData?.userData?.joiningDate)}</p>
                {numberOfYears(employeeData?.userData?.joiningDate) !== 0 && (
                    <p className='text-center'>({numberOfYears(employeeData?.userData?.joiningDate)} years)</p>
                )}
            </div>
        </div>
    )
}

function numberOfYears(date){
    const givenDate = new Date(date);
    const currentDate = new Date();
    let yearsDifference = currentDate.getFullYear() - givenDate.getFullYear();

    if ( currentDate.getMonth() < givenDate.getMonth() || (currentDate.getMonth() === givenDate.getMonth() && currentDate.getDate() < givenDate.getDate())) {
        yearsDifference--;
    }
    return yearsDifference;
}

export default Events