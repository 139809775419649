import React from 'react'

const Checkbox = ({checked=false, type="default", onChange=()=>{}, className="", ...props}) => {
    return (
        type === "design" ? (
            <div className={`checkboxes__item ${className}`}>
                <label className="checkbox style-d">
                    <input type="checkbox" checked={checked} onChange={onChange} />
                    <div className="checkbox__checkmark"></div>
                </label>
            </div>
        ):(
            <input
                type="checkbox"
                checked={checked}
                className='select-none cursor-pointer'
                onChange={onChange}
                {...props}
            />
        )
    )
}

export default Checkbox;