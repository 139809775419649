import React, { useEffect } from 'react';
import Layout from '../../layout/default';
import CurrentPlan from '../../components/account/currentPlan';
import UpdatePlan from '../../components/subscription/updatePlan';
import NextPlan from '../../components/subscription/nextPlan';
import { GetRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionActions } from '../../store/subscription-slice';
import PlanHistory from '../../components/subscription/planHistory';

const Subscription = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector(state => state.account)
    const { nextPlan, allPlans, planTrigger, pageCount, totalSubscriptionCount } = useSelector((state) => state.subscription);

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/subscription/next/${accountInfo.data._id}`).then((response) => {
                dispatch(subscriptionActions.setNextPlan(response.data))
            }).catch(error => {
                console.log("error getting next plan", error)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, planTrigger])

    useEffect(() => {
        if (accountInfo) {
            getAllSubscriptions(pageCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, planTrigger])

    const getAllSubscriptions = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/subscription/all/${accountInfo.data._id}?page=${page}&limit=10`).then((response) => {
            dispatch(subscriptionActions.setAllPlans(response.data.plans))
            dispatch(subscriptionActions.setTotalSubscriptionCount(response.data.totalCount))
        }).catch(error => {
            console.log("error getting plan data", error)
        })
    }

    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px]'>
                    <h3>Subscription</h3>
                </div>
                <div>
                    <CurrentPlan />
                </div>
                {nextPlan && (
                    <div>
                        <NextPlan data={nextPlan} />
                    </div>
                )}
                <div>
                    <UpdatePlan />
                </div>
                {allPlans && (
                    <div>
                        <PlanHistory
                            data={allPlans}
                            onPageChange={getAllSubscriptions}
                            totalCount={totalSubscriptionCount}
                        />
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default Subscription;