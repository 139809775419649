import React from 'react'
import IconButton from '../custom/iconButton'
import { RiSendPlaneFill } from 'react-icons/ri'
import { PiStickerLight } from 'react-icons/pi'
import { useSelector } from 'react-redux'

const OtherActions = (props) => {
    const { message } = useSelector((state) => state.chat);
    return (
        <>
        <IconButton
            icon={<RiSendPlaneFill size={25} color='#fff' className={message?.length > 0 ? 'rotate-45 mr-[5px]' : ""} />}
            onClick={() => {
                props.messageHandler()
                if (props.inputRef?.current) {
                    const textarea = props.inputRef.current;
                    textarea.style.height = 'auto';
                }
            }}
            className={`!h-[50px] !min-w-[50px] bg-primary ${message?.length === 0 && "pointer-events-none"}`}
            title="Send"
        />
        {message?.length < 1 && (
            <IconButton
                icon={<PiStickerLight size={22} color='#264348' />}
                className="h-[40px] min-w-[40px] !bg-white hidden md:flex"
                onClick={() => {
                    props.setOpenEmoji(false)
                    props.setOpenSticker(prev => !prev)
                    if (props.inputRef?.current) {
                        const textarea = props.inputRef.current;
                        textarea.style.height = 'auto';
                    }
                }}
                title="Stickers"
            />
        )}
        {/* <IconButton
            icon={<MdOutlinePermMedia size={20} color='#264348' />}
            className="h-[40px] min-w-[40px] !bg-white hidden md:flex"
            onClick={handleIconClick}
            title="Media"
        />
        <input
            type="file"
            ref={props.fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/*"
        /> */}
        </>
    )
}

export default OtherActions;