import React, { useState } from 'react';
import Error from '../custom/error';
import TextInput from '../custom/textInput';
import Label from '../custom/label';
import Button from '../custom/button';
import { PostRequest } from '../../utils/request';
import { validateEmail } from '../../utils/formValidation';
import { uiActions } from '../../store/ui-slice';
import { useDispatch } from 'react-redux';

const ResetPassword = ({setPage=()=>{}, setDisabled=()=>{}, setReceived=()=>{} }) => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    
    const handleEmailInputChange = (e) => {
        const inputValue = e.target.value;
        const val = inputValue.replace(" ", '')?.toLowerCase();
        setEmail(val);
        setError("");
    };

    function sendEmailHandler() {
        setDisabled(true);
        setError('');
        if (!email) {
            setError('Email is required!');
            setDisabled(false);
        } else if (!validateEmail(email)) {
            setError('Invalid Email');
            setDisabled(false);
        }
        else {
            PostRequest(`${process.env.REACT_APP_URL}/account/forgot-password`, {
                email: email,
            }).then(response => {
                setDisabled(false);
                setReceived({otp: response.data?.otp, userId: response.data?.id })
                dispatch(uiActions.setToastify({
                    message: "One time Password has been sent to your mail!",
                    isSuccess: true
                }));
                setPage(1);
            }).catch(error => {
                setError(error?.data || "Error sending otp to email! Try again later!");
                setDisabled(false);
            });
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            sendEmailHandler();
        }
    };

    return (
        <div>
            <h2 className='text-center md:text-left'>Reset Password</h2>
            <p className='mb-[20px] text-[#aaa]'>We will send you OTP to your email to reset your password</p>
            <Error message={error} />
            <div className="mb-[15px]">
                <Label
                    title='Email'
                    htmlFor="Email"
                />
                <TextInput
                    value={email}
                    onChange={handleEmailInputChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <Button
                label="Reset"
                onClick={sendEmailHandler}
            />
        </div>
    )
}

export default ResetPassword;