import React, { useEffect, useState } from 'react'
import ReactModal from '.';
import TextInput from '../components/custom/textInput';
import { useSelector } from 'react-redux';
import Label from '../components/custom/label';
import Button from '../components/custom/button';
import { FaCheckCircle } from "react-icons/fa";

const EmailVerification = ({ open, setOpen=()=>{} }) => {
    const [selected, setSelected] = useState(0);
    function closeHandler(){
        setOpen(false)
        setSelected(0)
    }
    const component = [
        <FirstView setSelected={setSelected} />,
        <SecondView setSelected={setSelected} />,
        <ThirdView closeHandler={closeHandler} />
    ]
    return (
        <ReactModal open={open} close={closeHandler} maxWidth="600px" padding='20px' margin='10px' heading='Email Verification' >
            <div>
                <div className={`w-full max-h-[300px]`}>
                    {component[selected]}
                </div>
            </div>
        </ReactModal >
    )
}

function FirstView({ setSelected=()=>{} }){
    const { accountInfo } = useSelector((state) => state.account);
    function firstClick(){
        setSelected(1)
    }
    return(
        <div>
            <Label title='Confirm Email' />
            <TextInput 
                value={accountInfo?.data.email}
                disabled
            />
            <Button 
                label='Send OTP'
                onClick={firstClick}
                type='secondary'
                className='mt-[30px]'
            />
        </div>
    )
}

function SecondView({ setSelected=()=>{} }){
    const [otp, setOtp] = useState("");
    function secondClick(){
        setSelected(2)
    }
    return(
        <div>
            <p className='text-success text-[12px]'>One-Time Password has been sent to your email.</p>
            <Label title='Enter OTP' />
            <TextInput 
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
            />
            <Button 
                label='Confirm OTP'
                onClick={secondClick}
                type='secondary'
                className='mt-[30px]'
            />
        </div>
    )
}

function ThirdView({ closeHandler=()=>{} }){
    useEffect(() => {
        const timer = setTimeout(() => {
            closeHandler()
        }, 1500);
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return(
        <div className='py-[30px]'>
            <FaCheckCircle className='text-success mx-auto' fontSize={30} />
            <p className='text-[30px] text-success font-bold text-center'>Email Verified Successfully!</p>
        </div>
    )
}

export default EmailVerification;