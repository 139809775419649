import React, { useEffect, useRef, useState } from 'react';
import { GetRequest, PutRequest } from '../../utils/request';
import { useDispatch } from 'react-redux';
import RolesList from './rolesList';
import Permissions from '.';
import ProjectPermissions from './projectPermissions';
import { uiActions } from '../../store/ui-slice';

const RolePermission = ({ data, onPageChange = () => {}, totalCount }) => {
    const dispatch = useDispatch();
    const permissionSectionRef = useRef(null);
    const [allRoles, setAllRoles] = useState(data);

    const [selectedRole, setSelectedRole] = useState(null);
    const [rolePermission, setRolePermission] = useState(null);

    const [trigger, setTrigger] = useState(0);
    const [projectTrigger, setProjectTrigger] = useState(0);

    const [projectPermission, setProjectPermission] = useState([]);

    useEffect(() => {
        setAllRoles(data)
    }, [data])

    useEffect(() => {
        if (selectedRole) {
            GetRequest(`${process.env.REACT_APP_URL}/permission/role/${selectedRole._id}`).then(response => {
                permissionSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
                setRolePermission(response?.data);
            }).catch((error) => {
                console.log("permission error >", error);
            })
        }
    }, [selectedRole, trigger])

    useEffect(() => {
        if (selectedRole) {
            GetRequest(`${process.env.REACT_APP_URL}/permission/role/project/${selectedRole._id}`).then(response => {
                setProjectPermission(response?.data)
            }).catch((error) => {
                console.log("permission error >", error);
            })
        }
    }, [selectedRole, projectTrigger])

    function updatePermissionHandler(body) {
        PutRequest(`${process.env.REACT_APP_URL}/permission/role/${selectedRole._id}`, body).then((response) => {
            dispatch(uiActions.setToastify({
                message: "Permission updated successfully!",
                isSuccess: true
            }));
            setTrigger(prev => prev + 1)
        }).catch((error) => {
            console.log(error)
            dispatch(uiActions.setToastify({
                message: "Permission updation failed!",
                isSuccess: false
            }));
        });
    }

    function updateProjectPermissionHandler(id, body) {
        PutRequest(`${process.env.REACT_APP_URL}/permission/role/project?id=${selectedRole._id}&project=${id}`, body).then((response) => {
            dispatch(uiActions.setToastify({
                message: "Permission updated successfully!",
                isSuccess: true
            }));
            setProjectTrigger(prev => prev + 1)
        }).catch((error) => {
            console.log(error)
            dispatch(uiActions.setToastify({
                message: "Permission updation failed!",
                isSuccess: false
            }));
        });
    }

    return (
        <div className='mt-[20px] pb-[50px]'>
            {allRoles.length > 0 ? (
                <RolesList
                    data={allRoles}
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    onPageChange={onPageChange}
                    totalCount={totalCount}
                />
            ) : (
                <div className='text-center mt-[50px]'>
                    No Roles found!
                </div>
            )}
            {selectedRole && (
                <div ref={permissionSectionRef}>
                    <Permissions
                        label={`Role: ${selectedRole.title}`}
                        updatePermissionHandler={updatePermissionHandler}
                        permissions={rolePermission}
                    />
                    <ProjectPermissions
                        projectPermission={projectPermission}
                        updateProjectPermissionHandler={updateProjectPermissionHandler}
                    />
                </div>
            )}
        </div>
    )
}

export default RolePermission;