import React, { useState } from 'react';
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const Dropdown = ({ 
    id="", 
    disabled=false, 
    value="", 
    onChange=()=>{}, 
    className="", 
    options=[], 
    placeholder="--- select ---", 
    errorText="", 
    type="default",
    ...props 
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        onChange(option);
        setIsOpen(false);
    };

    return (
        type === "default" ? (
            <div className={`relative w-full ${className}`} onMouseLeave={() => setIsOpen(false)} {...props}>
                <button
                    id={id}
                    disabled={disabled}
                    onClick={handleToggle}
                    className={`h-[40px] capitalize w-full focus:outline-none border-[#c4c4c4] px-[10px] border focus:border-[#1E40AF] rounded-[5px] focus:shadow-[0_0_2px_#000000] text-[14px] bg-white ${disabled && "hover:border text-[#aaa]"} flex justify-between items-center`}
                >     
                    <span>{value || placeholder}</span>
                    <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''}`}><MdOutlineKeyboardArrowDown/></span>
                </button>
                {isOpen && options.length>0 && (
                    <div className="absolute z-[99999] w-full bg-white border border-[#c4c4c4] rounded-[5px] max-h-60 overflow-y-auto shadow-lg">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={() => handleSelect(option)}
                                className={`px-[10px] py-[8px] bg-hover cursor-pointer capitalize ${option?.title === selectedOption?.title  ? 'bg-green-400 text-white bg-hover  hover:text-black' : ''}`}
                                >
                                {option.title}
                            </div>
                        ))}
                    </div>
                )}
                
                {errorText && (
                    <span className='text-error text-[12px]'>{errorText}</span>
                )}
            </div>
        ):(
            <div className={`relative w-full ${className}`} {...props}>
                <select
                    id={id}
                    disabled={disabled}
                    value={value || ""}
                    onChange={(e) => handleSelect(options.find(option => option.title === e.target.value))}
                    className={`h-[40px] capitalize w-full focus:outline-none border-[#c4c4c4] px-[10px] border focus:border-[#1E40AF] rounded-[5px] focus:shadow-[0_0_2px_#000000] text-[14px] bg-white ${disabled ? "hover:border text-[#aaa]" : ""} flex justify-between items-center`}
                >
                    <option value="" disabled>{placeholder}</option>
                    {options.map((option, index) => (
                        <option
                            key={index}
                            value={option.title}
                            className={`capitalize text-[12px] ${option.title === selectedOption?.title ? 'bg-green-400 text-white' : ''}`}
                        >
                            {option.title}
                        </option>
                    ))}
                </select>

                {errorText && (
                    <span className='text-error text-[12px]'>{errorText}</span>
                )}
            </div>
        )
    );
};

export default Dropdown;
