import 'react-calendar/dist/Calendar.css';
import React, { useEffect, useState } from 'react';
import ReactCalendar from 'react-calendar';
import { useDispatch, useSelector } from 'react-redux';
import { GetRequest } from '../../utils/request';
import Layout from '../../layout';
import { eventActions } from '../../store/event-slice';
import { employeeActions } from '../../store/employee-slice';
import Pagination from '../../components/custom/pagination';
import EventsData from '../../components/events/eventsData';
import CreateEvent from '../../components/events/createEvent';
import Search from '../../components/custom/search';

const Events = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state?.account);
    const { events } = useSelector((state) => state?.event);

    const [event, setEvent] = useState({
        selectedDepartments: { title: "All" },
        selectedDepartmentId: null,
        selectedDate: null,
        eventName: "",
        eventTime: ""
    });
    const [searchKey, setSearchKey] = useState("")
    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(0);

    const role = accountInfo?.data.role.title?.toLowerCase();
    const isAdmin = accountInfo?.data?.adminId ? false : true;

    const clickDateHandler = (date) => {
        setEvent(prevEvent => ({ ...prevEvent, selectedDate: new Date(date) }));
    };

    useEffect(() => {
        setLoading(true);
        if (accountInfo) {
            const id = accountInfo.data?.role?.departmentId ? accountInfo.data?.adminId : accountInfo.data?._id;
            const url = `${process.env.REACT_APP_URL}/event/${id}` + (accountInfo.data?.role?.departmentId ? `?departmentid=${accountInfo.data?.role?.departmentId}` : '');
            GetRequest(url).then((response) => {
                const eventsWithDates = response?.data.map(event => ({
                    ...event,
                    eventDate: new Date(event.eventDate)
                }));
                dispatch(eventActions.setEvents(eventsWithDates));
                setLoading(false);
            }).catch((error) => {
                console.log(error, "fetch events error");
                setLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, accountInfo]);

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, trigger]);

    const permissions = accountInfo?.permissions;
    const currentDate = new Date();

    const isSameDay = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };

    const parseEventDateTime = (eventDate, eventTime) => {
        const [hours, minutes] = eventTime.split(':').map(Number);
        const date = new Date(eventDate);
        date.setHours(hours, minutes, 0, 0);
        return date;
    };

    const upcomingEvents = events?.filter(event => {
        const eventDateTime = parseEventDateTime(event.eventDate, event.eventTime);
        if (eventDateTime > currentDate) {
            return true;
        }
        if (isSameDay(eventDateTime, currentDate)) {
            if (eventDateTime > currentDate) {

                return true;
            } else {

            }
        }
        return false;
    });

    const expiredEvents = events?.filter(event => {
        const eventDateTime = parseEventDateTime(event.eventDate, event.eventTime);

        if (eventDateTime < currentDate) {
            return true;
        }
        if (isSameDay(eventDateTime, currentDate)) {

            if (eventDateTime <= currentDate) {
                return true;
            } else {

            }
        }
        return false;
    });

    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] w-full' />
            <ReactCalendar
                value={event.selectedDate}
                onClickDay={clickDateHandler}
                tileClassName={({ date }) =>
                    event.selectedDate &&
                        date.toDateString() === event.selectedDate.toDateString()
                        ? "!bg-strawberry text-white"
                        : events?.length > 0 && events.some((event) => event.eventDate.toDateString() === date.toDateString())
                            ? "!bg-[#ffd1dc] text-black"
                            : ""
                }
                className="!w-full"
            />
            <div className="mt-[30px] py-[50px]">
                {(role === "admin" || permissions?.addEvent) && (
                    <CreateEvent event={event} setTrigger={setTrigger} setEvent={setEvent} />
                )}

                <div className={(role === "admin" || permissions?.addEvent) ? "mt-[50px]" : "mt-0"}>
                    <div className='flex flex-col sm:flex-row justify-between items-center gap-[30px]'>
                        <h3>Upcoming Events</h3>
                        <Search
                            value={searchKey}
                            onChange={(e) => setSearchKey(e.target.value)}
                            placeholder="Lookin' for an event?"
                            className='max-w-[250px]'
                        />
                    </div>
                    {loading ? (
                        <div className="text-center mt-[30px]">Loading...</div>
                    ) : (
                        upcomingEvents?.length > 0 ? (
                            <Pagination
                                itemsPerPage={9}
                                data={upcomingEvents}
                                position='center'
                                type='round'
                                renderComponent={(currentItems) => (
                                    <EventsData
                                        data={currentItems}
                                        setTrigger={setTrigger}
                                    />
                                )}
                            />
                        ) : (
                            <p className="mt-[30px] text-center">No Upcoming Events Available</p>
                        )
                    )}
                </div>

                {isAdmin && expiredEvents?.length > 0 && (
                    <div className={(role === "admin" || permissions?.addEvent) ? "mt-[50px]" : "mt-0"}>
                        <h3>Expired Events</h3>
                        {loading ? (
                            <div className="text-center mt-[30px]">Loading...</div>
                        ) : (
                            <Pagination 
                                itemsPerPage={9}
                                data={expiredEvents}
                                position='center'
                                type='round'
                                renderComponent={(currentItems) => (
                                    <EventsData 
                                        data={currentItems} 
                                        setTrigger={setTrigger}
                                    />
                                )}
                            />
                        )}
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default Events;