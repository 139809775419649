import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetRequest } from '../../utils/request';
import Search from '../../components/custom/search';
import Layout from '../../layout';
import FilterTabs from '../../components/permissions/filterTabs';
import UserPermission from '../../components/permissions/userPermission';
import RolePermission from '../../components/permissions/rolePermission';
import { employeeActions } from '../../store/employee-slice';

const Permissions = () => {
    const dispatch = useDispatch();
    const { pageCount, totalDeptCount, totalEmployeesCount } = useSelector((state) => state.employee);

    const [allUsers, setAllUsers] = useState([]);
    const [allDepartments, setAllDepartments] = useState([])
    const { accountInfo } = useSelector((state) => state.account);

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchKey, setSearchKey] = useState("");

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (accountInfo) {
            getEmployees(pageCount)
            getDepartments(pageCount)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo])

    const getEmployees = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/user/employees/${accountInfo.data._id}?page=${page}&limit=10`).then(response => {
            setAllUsers(response.data.data);
            dispatch(employeeActions.setTotalEmployeesCount(response.data.totalEmpoyeesCount))
        }).catch((error) => {
            console.log("employee error >", error);
        })
    }

    const getDepartments = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/department/roles/all/${accountInfo.data._id}?page=${page}&limit=10`).then(response => {
            setAllDepartments(response.data.data);
            dispatch(employeeActions.setTotalDeptCount(response.data.totalCount))
        }).catch((error) => {
            console.log("department error >", error);
        })
    }


    // FILTER DATA THROUGH SEARCH BAR AND KEY
    useEffect(() => {
        if (searchKey.length > 0) {
            if (activeTab === 1) {
                const filtered = allUsers.filter(index => {
                    return index.fullName?.toLowerCase().startsWith(searchKey?.toLowerCase()) || index.email?.toLowerCase().startsWith(searchKey?.toLowerCase())
                })
                setFilteredResults(filtered);
            }
            else {
                const filtered = allDepartments.filter(index => {
                    return index.title?.toLowerCase().startsWith(searchKey?.toLowerCase()) || index.departmentId?.title.toLowerCase().startsWith(searchKey?.toLowerCase())
                });
                setFilteredResults(filtered);
            }
        } else {
            if (activeTab === 0) {
                setFilteredResults(allDepartments)
            } else if (activeTab === 1) {
                setFilteredResults(allUsers)
            } else {
                setFilteredResults(allUsers);
            }
        }
    }, [allUsers, searchKey, allDepartments, activeTab]);

    const components = [
        <RolePermission
            data={filteredResults}
            onPageChange={getDepartments}
            totalCount={totalDeptCount}
        />,
        <UserPermission
            data={filteredResults}
            onPageChange={getEmployees}
            totalCount={totalEmployeesCount}
        />
    ]

    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px]'>
                    <h3>Permissions</h3>
                    {/* {activeTab === 1 && ( */}
                    <Search
                        value={searchKey}
                        placeholder="Need a helping hand?"
                        onChange={(e) => setSearchKey(e.target.value)}
                        className='!max-w-[300px]'
                    />
                    {/* )} */}
                </div>

                <FilterTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setSearchKey={setSearchKey}
                />

                {components[activeTab]}
            </div>
        </Layout>
    )
}

export default Permissions;