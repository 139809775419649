import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../store/ui-slice';

const Pagination = ({
    itemsPerPage = 10,
    data = [],
    renderComponent,
    position = "right",
    type = "round",
    onPageChange,
    totalCount
}) => {
    const dispatch = useDispatch();
    const { paginationOffset } = useSelector(state => state.ui)

    const [lastPage, setLastPage] = useState(false)
    useEffect(() => {
        dispatch(uiActions.setPaginationOffset(0));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const pageCount = Math.ceil(totalCount / itemsPerPage);
    useEffect(() => {
        if (totalCount <= itemsPerPage) {
            setLastPage(true)
        }
    }, [])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage);
        dispatch(uiActions.setPaginationOffset(newOffset))
        onPageChange(event.selected + 1)
        const pageNumber = event.selected + 1
        if (pageNumber == pageCount || totalCount === itemsPerPage) {
            setLastPage(true)
        } else {
            setLastPage(false)
        }
    };

    return (
        <>
            {data && (
                renderComponent(data)
            )}
            <ReactPaginate
                breakLabel="..."
                nextLabel={<IoIosArrowForward size={15} color={lastPage && "#aaa"} />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<IoIosArrowBack size={15} color={paginationOffset === 0 && "#aaa"} />}
                renderOnZeroPageCount={null}
                activeClassName={`justify-center items-center flex ${activePageCss[type]}`}
                containerClassName={`pagination flex items-center ${positionCss[position]} ${containerPageCss[type]}`}
                pageClassName={`text-[12px] justify-center items-center flex ${pageCss[type]}`}
            />
        </>
    );
}

const pageCss = {
    table: "",
    box: "h-[30px] w-[25px] bg-white text-black border",
    round: "rounded-full h-[30px] w-[30px] bg-white text-black border"
}

const activePageCss = {
    table: "!bg-[#aaa] h-[20px] w-[20px]",
    box: "h-[30px] w-[25px] !bg-black !text-white border",
    round: "rounded-full h-[30px] w-[30px] !bg-black text-white border"
}

const containerPageCss = {
    table: "mt-0 bg-[#dcdcdc] py-[5px]",
    box: "!mt-[20px]",
    round: "!mt-[20px]"
}

const positionCss = {
    left: "justify-start",
    right: "justify-end",
    center: "justify-center"
}

export default Pagination;