import React from 'react';
import { IoMdAdd } from 'react-icons/io';
import { formatDate } from '../../utils/formatTimestamp';
import MilestoneAction from '../../popups/milestoneAction';
import AddTask from '../../popups/addTask';
import Avatar from '../custom/avatar';
import { useSelector } from 'react-redux';
import getInitialLetter from '../../utils/getInitialLetter';
import { Link } from 'react-router-dom';

const List = ({ projectId, setTrigger = () => { } }) => {

    const { activeProjectMilestones } = useSelector((state) => state.project);
    const { accountInfo } = useSelector((state) => state.account);
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";
    const { projectPermissions } = useSelector((state) => state.project);

    return (
        <div className="px-[30px] mt-[10px] pb-[50px] grid gap-[10px]">
            {activeProjectMilestones?.map((item, i) => (
                <div key={i} className='mb-[20px] last:mb-0'>

                    <div className='flex items-center mb-[10px]'>
                        {(isAdmin || accountInfo?.permissions?.updateProject) ? (
                            <MilestoneAction
                            icon={<h4 className="capitalize font-medium text-white text-[14px]">{item.title}</h4>}
                            milestoneData={item}
                            setTrigger={setTrigger}
                            style={{ backgroundColor: item.color }}
                            className="rounded-[5px] mr-[5px] px-[10px] py-[5px]"
                            />
                        ):(
                            <h4 style={{ backgroundColor: item.color }} className="inline-flex rounded-[5px] mr-[5px] px-[10px] py-[5px] capitalize font-medium text-[14px] text-white">{item.title}</h4>
                        )}
                        {(projectPermissions?.add || isAdmin) && (
                            <AddTask
                                icon={<IoMdAdd color="#000" size={16} />}
                                projectId={projectId}
                                item={item}
                                color={item.color}
                                milestones={activeProjectMilestones}
                                setTrigger={setTrigger}
                            />
                        )}
                    </div>

                    {item.tasks?.length > 0 ? (
                        <table className='w-full'>
                            <thead>
                                <tr className='border-b h-[40px]'>
                                    <td className='text-[14px] font-semibold'>Name</td>
                                    <td className='text-[14px] font-semibold'>Assignees</td>
                                    <td className='text-[14px] font-semibold'>Due Date</td>
                                    <td className=''></td>
                                </tr>
                            </thead>
                            <tbody>
                                {item.tasks.map((task, i) => {
                                    const assignees = [...task.assignees, ...task.departments];
                                    return (
                                        <tr key={i} className="px-[10px] border-b last:border-b group">
                                            <td className='w-[30%]'>
                                                <Link
                                                    to={`/task/${task._id}`}
                                                    className='border-none bg-white'
                                                >
                                                    <div className='flex gap-[10px] cursor-pointer'>
                                                        <div className='h-[13px] min-w-[13px] mt-[1px] rounded-full flex justify-center items-center border' style={{ borderColor: item.color }}>
                                                            <div className='h-[9px] w-[9px] rounded-full' style={{ backgroundColor: item.color }} />
                                                        </div>
                                                        <h6 className="text-[12px]">{task.title}</h6>
                                                    </div>
                                                </Link>
                                            </td>
                                            <td className='w-[30%] p-[10px]'>
                                                {assignees?.length > 0 ? (
                                                    <div className="relative flex items-center w-full" >
                                                        {assignees.slice(0, 5).map((item, i) => (
                                                            <>
                                                                {item.title ? (
                                                                    <div title={item.title} className='h-[40px] w-[40px] rounded-full border border-[#aaa] flex justify-center items-center ml-[-5px] first:ml-0 bg-secondary select-none'>
                                                                        {getInitialLetter(item.title)}
                                                                    </div>
                                                                ) : (
                                                                    <Avatar
                                                                        src={item.avatar}
                                                                        alt={item.fullName}
                                                                        title={item.fullName}
                                                                        key={i}
                                                                        noOnline
                                                                        className={`ml-[-5px] first:ml-0 cursor-default`}
                                                                    />
                                                                )}
                                                            </>
                                                        ))}
                                                        {assignees?.length > 5 && (
                                                            <div className='h-[40px] w-[40px] flex justify-center items-center border border-[#aaa] rounded-full bg-white ml-[-10px] relative'>+{assignees?.length - 3}</div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <p className='ml-[10px]'> --- </p>
                                                )}
                                            </td>
                                            <td className='w-[30%] p-[10px]'>
                                                <div className='text-[14px]'>{formatDate(task?.dueDate)}</div>
                                            </td>
                                            {(projectPermissions?.delete || isAdmin) && (
                                                <td className='w-[10%] hidden group-hover:block p-[10px]'>
                                                    {/* <IconButton
                                                        icon={<MdDelete size={20} className='text-[#000]' />}
                                                        onClick={() => deleteTaskHandler(task?._id)}
                                                        className='h-[40px] w-[40px] bg-transparent border-none'
                                                    /> */}
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <div className='text-[12px] text-left border-y p-[10px]'>No Tasks</div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default List;