import React from 'react'
import { CiCalendarDate } from 'react-icons/ci';
import { TbAlphabetLatin } from "react-icons/tb";
import { AiTwotoneMobile } from 'react-icons/ai';
import { MdAlternateEmail } from 'react-icons/md';
import { FaHashtag } from 'react-icons/fa6';
import { PiGenderIntersexDuotone } from 'react-icons/pi';
import { SiPrivateinternetaccess } from 'react-icons/si';
import { LuCakeSlice } from 'react-icons/lu';
import { formatDate } from '../../utils/formatTimestamp';
import { employeeActions } from '../../store/employee-slice';
import { useDispatch, useSelector } from 'react-redux';
import UpdateEmployee from '../../popups/updateEmployee';
import { TbNumbers } from "react-icons/tb";
import { HiLocationMarker } from 'react-icons/hi';

const Info = ({ triggerEmployee=()=>{} }) => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { updateEmployee, employeeData } = useSelector((state) => state.employee);

    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin" || false;
    const isAdminProfile = employeeData?.userData.adminId ? false : true;

    const showGender = employeeData?.settings ? employeeData.settings.showGender : true;
    const showBirthday = employeeData?.settings ? employeeData.settings.showBirthday : true;
    const updateEmploye = accountInfo?.permissions?.updateEmployee || false;

    const { departments } = useSelector((state) => state.employee);
    
    return(
        <div className="flex md:flex-row max-md:flex-col gap-[20px] bg-white p-[30px] my-[50px] relative">
            {updateEmployee && (
                <UpdateEmployee departments={departments} triggerEmployee={triggerEmployee} />
            )}
            {(isAdmin || updateEmploye) && (
                <div className='absolute top-[-15px] right-[30px]'>
                    <button 
                        onClick={() => dispatch(employeeActions.setUpdateEmployee(employeeData?.userData))} 
                        className='text-link'
                    >Edit</button>
                </div>
            )}
            <div className="w-full">
                {!isAdminProfile && (
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <TbNumbers size={18} className="mr-[10px]" /> Employee ID</div>
                        <span className='max-w-[400px] text-right capitalize'>{employeeData?.userData?.employeeID || "---"}</span>
                    </div> 
                )}
                <hr />
                <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                    <div className="capitalize flex items-center"> <TbAlphabetLatin size={18} className="mr-[10px]" /> Full Name</div>
                    <div className="">{employeeData?.userData?.fullName}</div>
                </div>
                <hr />
                <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                    <div className="capitalize flex items-center"> <MdAlternateEmail size={16} className="mr-[10px]" /> Email</div>
                    <div className="">{employeeData?.userData?.email}</div>
                </div>
                <hr />
                {(isAdmin || updateEmploye) && (
                    <>
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <AiTwotoneMobile size={16} className="mr-[10px]" /> Mobile</div>
                        <div>{employeeData?.userData?.mobile ? `${employeeData?.userData?.stdCode} ${employeeData?.userData?.mobile}` : "---"}</div>
                    </div>
                    <hr />
                    </>
                )}
                {!isAdminProfile && (
                    <>
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <FaHashtag size={16} className="mr-[10px]" /> Department</div>
                        <span className='max-w-[400px] text-right capitalize'>{employeeData?.userData?.role?.departmentId?.title || "---"}</span>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <FaHashtag size={16} className="mr-[10px]" /> Designation</div>
                        <div className="capitalize">{employeeData?.userData?.role?.title || "---"}</div>
                    </div>
                    <hr />
                    </>
                )}
                <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                    <div className="capitalize flex items-center"> <PiGenderIntersexDuotone size={20} className="mr-[10px]" /> Gender</div>
                    <div className="capitalize">
                        {(isAdmin || updateEmploye) ? employeeData?.userData?.gender : showGender ? employeeData?.userData?.gender : <SiPrivateinternetaccess title="Private" />}
                    </div>
                </div>
                <hr />
                <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                    <div className="capitalize flex items-center"> <LuCakeSlice size={18} className="mr-[10px]" /> Birthday</div>
                    <div className="capitalize">
                        {(isAdmin || updateEmploye) ? formatDate(employeeData?.userData?.birthday) : (
                            showBirthday
                                ? formatDate(employeeData?.userData?.birthday)
                                : (<SiPrivateinternetaccess title="Private" />)
                        )}
                    </div>
                </div>
                <hr />
                {!isAdminProfile && (
                    <>
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <CiCalendarDate size={18} className="mr-[10px]" /> Joined On</div>
                        <div className="capitalize">{formatDate(employeeData?.userData?.joiningDate)}</div>
                    </div>
                    <hr />
                    <div className='px-[10px] py-[15px] flex justify-between items-center gap-[10px]'>
                        <div className="capitalize flex items-center"> <HiLocationMarker size={18} className="mr-[10px]" /> Office Location</div>
                        <span className='max-w-[400px] text-right capitalize'>{employeeData?.userData?.officeLocation || "---"}</span>
                    </div>
                    <hr />
                    </>
                )}
            </div>
        </div>
    )
}

export default Info