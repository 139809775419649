import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GetRequest } from '../../utils/request';
import Search from '../../components/custom/search';
import Layout from '../../layout';
import FilterTabs from '../../components/permissions/filterTabs';
import UserPermission from '../../components/permissions/userPermission';
import RolePermission from '../../components/permissions/rolePermission';

const Permissions = () => {
    const [allUsers, setAllUsers] = useState([]);
    const { accountInfo } = useSelector((state) => state.account);

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchKey, setSearchKey] = useState("");

    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/user/employees/${accountInfo.data._id}`).then(response => {
                setAllUsers(response.data);
            }).catch((error) => {
                console.log("employee error >", error);
            })
        }
    }, [accountInfo])

    // FILTER DATA THROUGH SEARCH BAR AND KEY
    useEffect(() => {
        if (searchKey.length > 0) {
            const filtered = allUsers.filter(index => {
                return index.fullName?.toLowerCase().includes(searchKey?.toLowerCase()) || index.email?.toLowerCase().includes(searchKey?.toLowerCase())
            })
            setFilteredResults(filtered);
        } else {
            setFilteredResults(allUsers);
        }
    }, [allUsers, searchKey]);

    const components = [
        <RolePermission
            data={filteredResults}
        />,
        <UserPermission 
            data={filteredResults}
        />
    ]

    return (
        <Layout>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px]'>
                    <h3>Permissions</h3>
                    {activeTab === 1 && (
                        <Search
                            value={searchKey}
                            placeholder="Need a helping hand?"
                            onChange={(e) => setSearchKey(e.target.value)}
                            className='!max-w-[300px]'
                        />
                    )}
                </div>

                <FilterTabs 
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />

                {components[activeTab]}
            </div>
        </Layout>
    )
}

export default Permissions;