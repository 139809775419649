import React, { useEffect, useRef, useState } from 'react';
import Permissions from '.';
import ProjectPermissions from './projectPermissions';
import UsersList from './usersList';
import { GetRequest, PutRequest } from '../../utils/request';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';

const UserPermission = ({data, onPageChange = () => {}, totalCount}) => {
    const dispatch = useDispatch();  
    const permissionSectionRef = useRef(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userPermission, setUserPermission] = useState(null);
    const [trigger, setTrigger]= useState(0);
    const [projectTrigger, setProjectTrigger]= useState(0);

    const [projectPermission, setProjectPermission] = useState([]);

    useEffect(() => {
        if (selectedUser) {
            GetRequest(`${process.env.REACT_APP_URL}/permission/${selectedUser._id}`).then(response => {
                permissionSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
                setUserPermission(response?.data)
            }).catch((error) => {
                console.log("permission error >", error);
            })
        }
    }, [selectedUser, trigger])
    
    useEffect(() => {
        if (selectedUser) {
            GetRequest(`${process.env.REACT_APP_URL}/permission/project/${selectedUser._id}`).then(response => {
                setProjectPermission(response?.data)
            }).catch((error) => {
                console.log("permission error >", error);
            })
        }
    }, [selectedUser, projectTrigger])

    function updatePermissionHandler(body) {
        PutRequest(`${process.env.REACT_APP_URL}/permission/${selectedUser._id}`, body).then((response) => {
            dispatch(uiActions.setToastify({
                message: "Permission updated successfully!",
                isSuccess: true
            }));
            setTrigger(prev => prev+1)
        }).catch((error) => {
            console.log(error)
            dispatch(uiActions.setToastify({
                message: "Permission updation failed!",
                isSuccess: false
            }));
        });
    }

    function updateProjectPermissionHandler(id, body) {
        PutRequest(`${process.env.REACT_APP_URL}/permission/project?id=${selectedUser._id}&project=${id}`, body).then((response) => {
            dispatch(uiActions.setToastify({
                message: "Permission updated successfully!",
                isSuccess: true
            }));
            setProjectTrigger(prev => prev+1)
        }).catch((error) => {
            console.log(error)
            dispatch(uiActions.setToastify({
                message: "Permission updation failed!",
                isSuccess: false
            }));
        });
    }

    return (
        <div className='mt-[20px] pb-[50px]'>
            {data.length > 0 ? (
                <UsersList 
                    data={data}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                    onPageChange={onPageChange}
                    totalCount={totalCount}
                />
            ) : (
                <div className='text-center mt-[50px]'>
                    No Employees found!
                </div>
            )}
            {selectedUser && (
                <div ref={permissionSectionRef}>
                    <Permissions
                        label={`User: ${selectedUser.fullName}`}
                        permissions={userPermission}
                        updatePermissionHandler={updatePermissionHandler}
                        // ref={permissionSectionRef}
                    />
                    <ProjectPermissions
                        projectPermission={projectPermission}
                        updateProjectPermissionHandler={updateProjectPermissionHandler}
                    />
                </div>
            )}
        </div>
    )
}

export default UserPermission;