import React, { useEffect, useRef, useState } from 'react';
import { GiChatBubble } from "react-icons/gi";
import { useDispatch, useSelector } from 'react-redux';
import { GetRequest, PostRequest, PutRequest } from '../utils/request';
import ChatSocket from "../socket/chat-socket";
import MessageRequest from '../components/chat/messageRequest';
import InputActions from '../components/chat/inputActions';
import { chatActions } from '../store/chat-slice';
import BlockedMessage from '../components/chat/blockedMessage';
import { encrypt } from '../utils/encryption';
import SingleMessage from '../components/chat/singleMessage';
import { useParams } from 'react-router-dom';
import ForwardMessage from '../popups/forwardMessage';
import { uiActions } from '../store/ui-slice';
import DefaultLayout from "../layout/default";

const Chat = () => {
    const { conversationId } = useParams();
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const chatRef = useRef(null);
    const messageRef = useRef(null);
    const inputRef = useRef(null);
    
    const { accountInfo } = useSelector((state) => state.account);
    const { chatTheme } = useSelector(state => state.ui);
    const {
        currentChat,
        replyData,
        allMessages,
        currentChatRequest,
        isSearched,
        triggerChat,
        message,
        editing,
        currentPage,
        wasLastList,
    } = useSelector((state) => state.chat);

    const [image, setImage] = useState(null);
    const [openEmoji, setOpenEmoji] = useState(false);
    const [receivedmsg, setReceivedmsg] = useState([]);

    const [selectedMessage, setSelectedMessage] = useState({
        emoji: "",
        action: ""
    })

    ChatSocket.useMessageEvent();
    ChatSocket.useMyMessageEvent(setReceivedmsg);
    ChatSocket.useFriendChatEvent();
    ChatSocket.useTypingIndicatorEvent(currentChat?.userData?._id, accountInfo?.data?._id);
    ChatSocket.useShowReactionEvent(receivedmsg, setReceivedmsg);
    ChatSocket.useRemoveMessageEvent();
    ChatSocket.useSingleMsgSeenEvent(receivedmsg, setReceivedmsg);
    ChatSocket.useEditMsgEvent();

    const blockedByMe = currentChat?.blockedBy?.includes(accountInfo?.data._id) || false;
    const blockedBy = currentChat?.blockedBy || [];

    useEffect(() => {
        if (conversationId && accountInfo) {
            dispatch(chatActions.setOpenSelection(false))
            dispatch(chatActions.setSelectedMessages([]))
            GetRequest(`${process.env.REACT_APP_URL}/conversation?userId=${accountInfo?.data?._id}&convoId=${conversationId}`).then(response => {
                dispatch(chatActions.setCurrentChat(response.data))
            }).catch(error => {
                console.log("conversation error >>>", error?.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId, accountInfo])

    function getRequest(id) {
        GetRequest(`${process.env.REACT_APP_URL}/request/${id}`).then(response => {
            dispatch(chatActions.setCurrentChatRequest(response.data))
        }).catch(error => {
            console.log("fetch current request error >>>", error);
        });
    };

    function messageHandler() {
        setOpenEmoji(false)
        if (message.trim() !== '') {
            const formData = new FormData();
            formData.append('conversationId', currentChat?.convoId || "");
            formData.append('from', accountInfo?.data._id);
            formData.append('to', currentChat?.userData?._id);
            formData.append('message', encrypt(message));
            if (currentChat && currentChat?.blockedBy?.length > 0) {
                formData.append('blockedBy', currentChat?.blockedBy)
            }
            if (replyData && replyData?._id) {
                formData.append('replyTo', replyData._id);
            }
            PostRequest(`${process.env.REACT_APP_URL}/message`, formData).then(response => {
                dispatch(chatActions.setReplyData(null));
                dispatch(chatActions.setIsSearched(true))
                if (!currentChat.convoId) {
                    ChatSocket.emitSendMsgRequest(response?.data?.message?.to)
                    dispatch(chatActions.setCurrentChat({ ...currentChat, convoId: response.data.convoId }))
                    ChatSocket.emitFirstMessage(response.data.message, accountInfo?.data);
                    dispatch(chatActions.setTriggerChat(triggerChat + 1))
                }
                else {
                    ChatSocket.emitNewMessage(response.data.message, accountInfo?.data);
                }
                dispatch(chatActions.setMessage(""))
            }).catch(error => {
                console.log("message error >>>", error);
                dispatch(uiActions.setToastify({
                    isSuccess: false,
                    message: error?.data || "Error sending message!"
                }))
            });
        }
    }

    function editMessageHandler() {
        PutRequest(`${process.env.REACT_APP_URL}/message/${editing}`, {
            message: encrypt(message),
            isEdited: true
        }).then(response => {
            ChatSocket.emitEditMessage(currentChat?.userData?._id)
            dispatch(chatActions.setEditing(null))
            dispatch(chatActions.setMessage(""))
            dispatch(chatActions.setTriggerChat(triggerChat + 1));

        }).catch(error => {
            console.log("edit message error >>>", error)
        })
    }

    const getMessages = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/message?id=${currentChat.convoId}&page=${page}&limit=${page > 1 ? 10 : 30}&userId=${accountInfo?.data._id}`).then(response => {
            if (response.data.length === 0) {
                dispatch(chatActions.setWasLastList(true))
                const timer = setTimeout(() => {
                    dispatch(chatActions.setLoadingChat(false))
                }, 1000);
                return () => clearTimeout(timer);
            }
            const filteredMessage = response.data.reverse()
            if (page === 1) {
                dispatch(chatActions.setAllMessages(filteredMessage))
                setReceivedmsg([])
            } else {
                dispatch(chatActions.setAllMessages([...filteredMessage, ...allMessages]))
            }
            const timer = setTimeout(() => {
                dispatch(chatActions.setLoadingChat(false))
            }, 2000);
            return () => clearTimeout(timer);
        }).catch(error => {
            console.log("fetch message error >>>", error);
        });
    };

    const emBlocked = currentChat?.blockedBy?.length > 0 ? currentChat?.blockedBy.includes(currentChat?.userData?._id) : false

    useEffect(() => {
        if (chatRef.current) {
            if (currentChat?.blockedBy?.length > 0){
                if (emBlocked) chatRef.current.scroll({ top: chatRef.current.scrollHeight, behavior: 'smooth' });
            }else{
                chatRef.current.scroll({ top: chatRef.current.scrollHeight, behavior: 'smooth' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivedmsg, emBlocked]);

    useEffect(() => {
        if (currentChat && currentChat?.convoId) {
            getMessages(1);
            dispatch(chatActions.setCurrentPage(1))
            dispatch(chatActions.setWasLastList(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerChat, currentChat]);

    useEffect(() => {
        if (accountInfo && currentChat?.convoId) {
            getRequest(currentChat.convoId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo, currentChat, isSearched])

    function messageActionHandler(id) {
        if (selectedMessage.action) {
            setSelectedMessage((prevState) => ({ ...prevState, action: "" }))
        } else {
            setSelectedMessage((prevState) => ({ ...prevState, action: id }))
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (message.length > 0) {
                if (editing) {
                    editMessageHandler();
                } else {
                    messageHandler();
                }
            }
        }
        const textarea = event.target;
        textarea.style.height = '50px';
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            // Handle non-image file selection
            alert("Please select an image file.");
        }
    };

    const handleIconClick = () => {
        fileInputRef.current.click();
        setOpenEmoji(false)
    };

    const handleEmojiClick = (emoji, event) => {
        if (event.pointerType === "mouse") {
            dispatch(chatActions.setMessage(message + emoji?.emoji))
            inputRef.current?.focus();
        }
        else {
            messageHandler()
        }
    };

    function togglePicker(id) {
        if (selectedMessage.emoji && selectedMessage.emoji.toString() === id) {
            setSelectedMessage((prevState) => ({ ...prevState, emoji: "" }))
        } else {
            setSelectedMessage((prevState) => ({ ...prevState, emoji: id }))
        }
    };

    return (
        <DefaultLayout 
            bgClass={chatTheme.className} 
            className='!max-w-none !px-0 !md:px-0 !lg:px-0' 
        >
            <ForwardMessage />
            <div 
                ref={chatRef}
                className='h-[calc(100vh_-_120px)] overflow-hidden overflow-y-auto'
            >
                {currentChat ? (
                    <>
                        <div className='w-full pt-[20px] px-[10px] flex-end max-w-[1070px] mx-auto mb-[30px]' >
                            {/* {/ GET REQUEST OR SHOW PENDING APPROVAL /} */}
                            {(blockedBy?.length === 0) && currentChat?.userData?._id && currentChatRequest && (
                                <MessageRequest
                                    getRequest={getRequest}
                                    request={currentChatRequest}
                                />
                            )}

                            {allMessages.length > 30 && !wasLastList && (
                                <div className='flex justify-center'>
                                    <button
                                        onClick={() => {
                                            console.log(currentPage, "wwoww")
                                            dispatch(chatActions.setCurrentPage(currentPage + 1))
                                            getMessages(currentPage + 1)
                                        }}
                                        className='text-center text-[#aaa] hover:underline mx-auto text-[12px]'
                                    >Load More</button>
                                </div>
                            )}

                            {/* {/ PREVIOUS MESSAGES /} */}
                            {allMessages?.length > 0 && allMessages.map((item, i) => (
                                <SingleMessage
                                    i={i}
                                    item={item}
                                    messageActionHandler={messageActionHandler}
                                    allMessages={allMessages}
                                    selectedAction={selectedMessage.action}
                                    selectedEmoji={selectedMessage.emoji}
                                    togglePicker={togglePicker}
                                    messageRef={messageRef}
                                />
                            ))}

                            {/* {/ LIVE MESSAGES /} */}
                            {receivedmsg.length > 0 && receivedmsg.map((item, i) => (
                                ((item.data.from === accountInfo?.data?._id || item.data.from === currentChat?.userData?._id) &&
                                    (item.data.to === accountInfo?.data?._id || item.data.to === currentChat?.userData?._id)) && (
                                    <SingleMessage
                                        i={i}
                                        item={item?.data}
                                        messageActionHandler={messageActionHandler}
                                        allMessages={receivedmsg}
                                        selectedAction={selectedMessage.action}
                                        selectedEmoji={selectedMessage.emoji}
                                        togglePicker={togglePicker}
                                        messageRef={messageRef}
                                    />
                                )
                            ))}
                        </div>

                        {/* {/ CHAT INPUT ACTIONS /} */}
                        {(!blockedByMe && (!currentChatRequest || (currentChatRequest && currentChatRequest?.from?._id === accountInfo?.data?._id))) && (
                            <InputActions
                                fileInputRef={fileInputRef}
                                image={image}
                                inputRef={inputRef}
                                setImage={setImage}
                                openEmoji={openEmoji}
                                setOpenEmoji={setOpenEmoji}
                                handleEmojiClick={handleEmojiClick}
                                messageHandler={editing ? editMessageHandler : messageHandler}
                                handleIconClick={handleIconClick}
                                handleFileChange={handleFileChange}
                                handleKeyDown={handleKeyDown}
                            />
                        )}

                        {/* IF BLOCKED THEN DISPLAY MESSAGE */}
                        {blockedByMe &&
                            <BlockedMessage blockedByMe={blockedByMe} />
                        }
                    </>
                ) : (
                    <div className='w-full text-center h-[90vh] flex flex-col justify-center items-center'>
                        <GiChatBubble size={40} color='#adaee5' className='animate-bounce' />
                        <p className='mt-[10px] text-[20px]'>Select chat to continue...</p>
                    </div>
                )}

            </div>
        </DefaultLayout>
    )
}

export default Chat;