import React, { useEffect, useState, useRef } from 'react';
import TextInput from '../../../components/custom/textInput';
import Label from '../../../components/custom/label';
import Dropdown from '../../../components/custom/dropdown';
import Gender from '../../../components/shared/gender';
import { checkEmptyFields, validateEmail, validatePassword, validatePhone } from '../../../utils/formValidation';
import Error from '../../../components/custom/error';
import { GetRequest, PostRequest } from '../../../utils/request';
import { useSelector, useDispatch } from 'react-redux';
import ExpiredPlan from '../../../components/shared/expiredPlan';
import { formatDateForCompare } from '../../../utils/formatTimestamp';
import { subscriptionActions } from '../../../store/subscription-slice';
import { FaRegEye, FaRegEyeSlash, FaUserTie } from 'react-icons/fa6';
import Papa from 'papaparse';
import { RiEditFill } from "react-icons/ri";
import Button from '../../../components/custom/button';
import { employeeActions } from '../../../store/employee-slice';
import Layout from '../../../layout';
import BulkEmployeeUpload from '../../../components/employee/bulkUpload';
import { MdGroups } from "react-icons/md";
import { uiActions } from '../../../store/ui-slice';

const AddEmployee = ({ setTrigger = () => { } }) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const { accountInfo } = useSelector((state) => state.account);
    const { planTrigger, currentPlan } = useSelector((state) => state.subscription);
    const { departments, employeeTrigger } = useSelector((state) => state.employee)
    const [error, setError] = useState("");
    const [fileError, setFileError] = useState("");
    const [roles, setRoles] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [showPass, setShowPass] = useState(false);
    const [file, setFile] = useState([]);
    const [openBulkUpload, setOpenBulkUpload] = useState(false);
    const [success, setSuccess] = useState({
        singleUpload: "",
        bulkUpload: ""
    })

    const [formInput, setFormInput] = useState({
        fullName: "",
        email: "",
        password: "",
        gender: "prefer not to say",
        department: "",
        role: "",
        birthday: null,
        joiningDate: null,
        employeeID: "",
        officeLocation: "",
        mobile: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormInput((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const nameHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const idHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const emailHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", "")?.toLowerCase();
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    //PHONE NUMBER INPUT HANDLER
    const phoneHandler = (e) => {
        const { name, value } = e.target
        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length <= 10) {
            setFormInput((prevState) => ({ ...prevState, [name]: numericValue }));
            setError("");
        }
    };

    const passwordHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", "");
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data.adminId || accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo])

    useEffect(() => {
        if (selectedDepartment && selectedDepartment._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${selectedDepartment._id}`).then(response => {
                setRoles(response.data);
                setSelectedRole(null);
                setTrigger(prev => prev + 1)
            }).catch((error) => {
                console.error("fetch roles error >", error);
                setRoles([]);
            });
        } else {
            setRoles([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartment]);

    const expectedColumns = ['employeeID', 'fullName', 'email', 'mobile', 'birthday', 'joiningDate', 'password', 'gender', 'officeLocation'];

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const fileType =
            file?.name.split('.').pop().toLowerCase();
        if (fileType !== 'csv') {
            setFileError('Please upload a CSV file.');
            return;
        }
        Papa.parse(file, {
            complete: function (results) {
                const csvHeader = results.data[0]; // The first row contains the header
                const dataRows = results.data.slice(1); // Remaining rows contain the data
                if (!dataRows) {
                    setFileError("No data available in the CSV")
                    return;
                }
                const filteredData = dataRows.filter(row => row.every(field => field?.trim() !== ''));
                const missingColumns = expectedColumns.filter(col => !csvHeader.includes(col));

                if (missingColumns.length > 0) {
                    setFileError(`Missing columns in CSV: ${missingColumns.join(", ")}`);
                    return;
                }

                const formattedData = filteredData.map(row => {
                    let rowObject = {};
                    csvHeader.forEach((header, index) => {
                        rowObject[header] = row[index];
                    });
                    return rowObject;
                });
                setFile(formattedData);
                setOpenBulkUpload(true)
                setFileError("");
            },
            error: function (error) {
                console.error("Error parsing CSV:", error);
                setFileError('Error parsing CSV file.');
            }
        });
    };

    const handleSubmit = () => {
        setError("");
        const impFields = {
            fullName: formInput?.fullName,
            email: formInput?.email,
            gender: formInput?.gender,
            role: formInput?.role,
            birthday: formInput?.birthday,
            joiningDate: formInput?.joiningDate,
            password: formInput?.password,
            mobile: formInput?.mobile
        }

        if (checkEmptyFields(impFields)) {
            setError("*Fields must not be empty");
        } else if (!validateEmail(formInput.email)) {
            setError("Email is invalid");
        } else if (!validatePhone(formInput.mobile)) {
            setError("Phone is invalid!");
        } else if (!validatePassword(formInput.password)) {
            setError("Password should contain at least 8 characters and must contain one uppercase, one lowercase, one digit, and one special character!");
        } else {
            const formData = new FormData();
            formData.append('fullName', formInput?.fullName?.trim());
            formData.append('email', formInput?.email?.trim());
            formData.append('password', formInput?.password);
            formData.append('gender', formInput?.gender);
            formData.append('employeeID', formInput?.employeeID?.trim());
            formData.append('officeLocation', formInput?.officeLocation?.trim()?.toLowerCase());
            formData.append('role', formInput?.role);
            formData.append('mobile', formInput?.mobile);
            if (formInput?.birthday) {
                formData.append('birthday', formInput?.birthday);
            }
            if (formInput?.birthday) {
                formData.append('joiningDate', formInput?.joiningDate);
            }

            PostRequest(`${process.env.REACT_APP_URL}/user/employee/${accountInfo?.data?.adminId || accountInfo?.data._id}`, formData).then((response) => {
                setFormInput({
                    fullName: "",
                    email: "",
                    password: "",
                    gender: "prefer not to say",
                    department: "",
                    role: ""
                })
                dispatch(uiActions.setToastify({
                    isSuccess: true,
                    message: "Employee added successfully"
                }))
                setSelectedDepartment(null)
                setSelectedRole(null)
                setError("")
                dispatch(employeeActions.setEmployeeTrigger(employeeTrigger + 1))
                dispatch(subscriptionActions.setPlanTrigger(planTrigger + 1))
            }).catch((error) => {
                console.log(error)
                setError(error.data)
            })
        }
    }

    useEffect(() => {
        if (success.bulkUpload || success.singleUpload) {
            const timer = setTimeout(() => {
                setSuccess({
                    singleUpload: "",
                    bulkUpload: ""
                })
            }, 2000);
            return () => clearTimeout(timer)
        }
    }, [success])

    const handleDownloadSampleCSV = () => {
        const sampleData = [
            expectedColumns,
            ["EMP1011", "demouser", "example@gmail.com", "9999988888", "01/01/2001", "01/01/2024", "User@1234", "male", "Mohali"]
        ]; const csvContent = Papa.unparse(sampleData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Employee_Sample.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    let currentdate = formatDateForCompare(new Date());
    const isExpired = currentPlan && formatDateForCompare(currentPlan.expiryOn) < currentdate;

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            handleSubmit()
        }
    };
    return (
        <Layout containerClassName='max-w-[1500px]'>
            <div className='pt-[30px] lg:pt-[50px] pb-[50px]'>
                <div className='flex flex-col md:flex-row justify-between items-center gap-[30px]'>
                    <h3>Create Employee</h3>
                </div>
                <div className='py-[50px]'>
                    {isExpired ? (
                        <ExpiredPlan />
                    ) : (
                        <>
                            <div className='flex gap-2 items-center border-b mb-[12px]'>
                                <FaUserTie size={15} color='#aaa' />
                                <h6 className='text-[#aaa]'>Single Upload</h6>
                            </div>
                            {error && <Error message={error} />}
                            {success && success.singleUpload &&
                                <div className={`text-[#228B22]`}>{success.singleUpload}</div>
                            }
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-[20px] gap-y-[10px]'>
                                <div>
                                    <Label title='Employee ID' />
                                    <TextInput
                                        name="employeeID"
                                        value={formInput.employeeID}
                                        onChange={idHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                        maxLength={30}
                                    />
                                </div>
                                <div>
                                    <Label title='Full Name' isImportant />
                                    <TextInput
                                        name="fullName"
                                        value={formInput.fullName}
                                        onChange={nameHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Email' isImportant />
                                    <TextInput
                                        name="email"
                                        value={formInput.email}
                                        onChange={emailHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Mobile' isImportant />
                                    <TextInput
                                        name="mobile"
                                        value={formInput.mobile}
                                        onChange={phoneHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Birthday' isImportant />
                                    <TextInput
                                        name="birthday"
                                        type="date"
                                        value={formInput.birthday || ''}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            const [year, month, day] = value.split("-");

                                            if ((year && year.length > 4) || (month && month.length > 2) || (day && day.length > 2)) {
                                                return;
                                            }

                                            setFormInput((prevInput) => ({
                                                ...prevInput,
                                                [name]: value,
                                            }));
                                        }}
                                        className="mt-[5px]"
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div className='relative'>
                                    <Label title='Password' isImportant />
                                    <TextInput
                                        name="password"
                                        type={showPass ? "text" : "password"}
                                        value={formInput.password}
                                        onChange={passwordHandler}
                                        className='mt-[5px]'
                                        placeholder="********"
                                        onKeyDown={handleKeyDown}
                                    />
                                    {showPass ? (
                                        <button onClick={() => setShowPass(false)} className='absolute right-[10px] top-[70%] translate-y-[-50%]' >
                                            <FaRegEyeSlash size={15} title="Hide" />
                                        </button>
                                    ) : (
                                        <button onClick={() => setShowPass(true)} className='absolute right-[10px] top-[70%] translate-y-[-50%]' >
                                            <FaRegEye size={15} title="Show" />
                                        </button>
                                    )}
                                </div>
                                <div>
                                    <Label title='Department' isImportant />
                                    <Dropdown
                                        value={selectedDepartment ? selectedDepartment.title : ""}
                                        onChange={(option) => {
                                            setSelectedDepartment(option);
                                            setFormInput((prevState) => ({
                                                ...prevState,
                                                department: option._id
                                            }));
                                        }}
                                        options={departments}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Designation' isImportant />
                                    <Dropdown
                                        value={selectedRole ? selectedRole.title : ""}
                                        onChange={(option) => {
                                            setSelectedRole(option);
                                            setFormInput((prevState) => ({
                                                ...prevState,
                                                role: option._id
                                            }))
                                        }}
                                        options={roles}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>

                                <div>
                                    <Label title='Date of Joining' isImportant />
                                    <TextInput
                                        name="joiningDate"
                                        type="date"
                                        value={formInput.joiningDate || ''}
                                        onChange={(e) => {
                                            const { name, value } = e.target;
                                            const [year, month, day] = value.split("-");

                                            if ((year && year.length > 4) || (month && month.length > 2) || (day && day.length > 2)) {
                                                return;
                                            }

                                            setFormInput((prevInput) => ({
                                                ...prevInput,
                                                [name]: value,
                                            }));
                                        }}
                                        className="mt-[5px]"
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                                <div>
                                    <Label title='Office Location' />
                                    <TextInput
                                        name="officeLocation"
                                        value={formInput.officeLocation}
                                        onChange={nameHandler}
                                        className='mt-[5px]'
                                        onKeyDown={handleKeyDown}
                                        maxLength={50}
                                    />
                                </div>
                                <div>
                                    <Label title='Gender' isImportant />
                                    <Gender
                                        value={formInput.gender}
                                        onClick={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex justify-end mt-[30px]'>
                                <Button
                                    label='Create'
                                    onClick={handleSubmit}
                                    type='secondary'
                                    className='mt-[20px] max-w-[200px]'
                                />
                            </div>
                            <div className='text-center mt-[30px] text-[14px] font-semibold text-[#aaa]'>
                                -------------------- OR --------------------
                            </div>
                            <div className='my-[30px] relative'>
                                <div className='flex gap-2 items-center border-b'>
                                    <MdGroups size={22} color='#aaa' />
                                    <h6 className='text-[#aaa]'>Bulk Upload</h6>
                                </div>
                                {fileError && <Error message={fileError} />}
                                {success && success.bulkUpload &&
                                    <div className={`text-[#228B22]`}>{success.bulkUpload}</div>
                                }
                                {openBulkUpload ? (
                                    <BulkEmployeeUpload
                                        open={openBulkUpload}
                                        setOpenBulkUpload={setOpenBulkUpload}
                                        data={file}
                                        departments={departments}
                                        setFile={setFile}
                                        setFileError={setFileError}
                                        setSuccess={setSuccess}
                                        fileInputRef={fileInputRef}
                                        setTrigger={setTrigger}

                                    />
                                ) : (
                                    <div className='flex justify-between items-center'>
                                        <input type="file" accept=".csv" ref={fileInputRef} onChange={handleFileChange} className='mt-[15px]' />
                                        {file.length > 0 && (
                                            <RiEditFill
                                                className='mt-3'
                                                onClick={() => setOpenBulkUpload(true)}
                                                style={{ cursor: 'pointer' }}
                                                title="Edit Bulk Upload"
                                            />
                                        )}
                                        <button
                                            onClick={handleDownloadSampleCSV}
                                            className=" top-3 right-0 mt-4 mr-2 text-blue-500 underline"
                                        >
                                            Download Sample CSV
                                        </button>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

            </div>
        </Layout>
    );
};

export default AddEmployee;