import React, { useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { GetRequest, PutRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import { employeeActions } from '../../store/employee-slice';

const Ratings = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { allRatings, employeeData } = useSelector((state) => state.employee);
    const [trigger, setTrigger] = useState(0)
    const isAdmin = accountInfo?.data.role?.title?.toLowerCase() === "admin";
    const updateEmployee = accountInfo?.permissions?.updateEmployee || false;

    let existingRating = employeeData?.rating;
    const [rating, setRating] = useState({
        total: 0,
        performance: existingRating?.performance || 0,
        behaviour: existingRating?.behaviour || 0,
        teamBonding: existingRating?.teamBonding || 0
    });

    // FETCHES CURRENT TOTAL RATINGS LABEL
    const getTotalRatingLabel = (rating) => {
        const totalRating = ((rating?.performance + rating?.behaviour + rating?.teamBonding) / 3).toFixed(1);
        return performanceLabel.find(({ range }) => totalRating >= range[0] && totalRating <= range[1]);
    };

    // CALCULATE TOTAL RATINGS
    const getTotalRating = (rating) => {
        const totalRating = ((rating?.performance + rating?.behaviour + rating?.teamBonding)/3).toFixed(1) || 5;
        return totalRating;
    }
    const totalRatingLabel = getTotalRatingLabel(rating);

    // UPDATES RATING
    function updateRating(body){
        PutRequest(`${process.env.REACT_APP_URL}/rating/${employeeData?.userData?._id}`, body).then(response => {
            setTrigger(prev => prev+1)
            dispatch(employeeActions.setEmployeeData({
                ...employeeData,
                rating: response.data
            }))
            setRating((prevState) => ({
                ...prevState,
                performance: response.data?.performance,
                behaviour: response.data?.behaviour,
                teamBonding: response.data?.teamBonding
            }))
        }).catch(error => {
            console.error("error >", error)
            dispatch(uiActions.setToastify({
                isSuccess: false,
                message: 'Failed to update ratings!'
            }))
        });
    }

    //FETCHES EMPLOYEES ALL RATINGS
    useEffect(() => {
        if (employeeData?.userData){
            GetRequest(`${process.env.REACT_APP_URL}/rating/${employeeData?.userData?._id}`).then(response => {
                dispatch(employeeActions.setAllRatings(response.data))
            }).catch(error => {
                console.error("previous ratings error >", error)
                dispatch(uiActions.setToastify({
                    isSuccess: false,
                    message: 'Failed to fetch previous ratings!'
                }))
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[employeeData, trigger])
    
    return (
        <div className="bg-white md:p-[30px] max-md:px-[15px] md:my-[50px] max-md:my-[20px]">
            <div>
                {(rating?.performance !== 0 || rating?.behaviour !== 0 || rating?.teamBonding !== 0) ? (
                    <div className='mb-[20px] max-w-[600px] flex items-end gap-[10px]'>
                        <span className='text-[50px] text-strawberry'>{getTotalRating(rating)}/5</span>
                        <span className='text-[20px] text-strawberry mb-[10px]' style={{ color: totalRatingLabel?.color}}>{totalRatingLabel?.label}</span>
                    </div>
                ):(
                    <div className='text-[#aaa] text-[20px] mb-[20px]'>No Ratings Yet!</div>
                )}
                <div className="grid grid-cols-2 items-center">
                    <p className='text-left'>Performance</p>
                    <Rating
                        onClick={(rate) => {
                            updateRating({performance: rate})
                            setRating((prevState) => ({
                                ...prevState,
                                performance: rate
                            }))
                        }}
                        readonly={!isAdmin ? !updateEmployee : !isAdmin}
                        allowHover={isAdmin ? isAdmin : updateEmployee}
                        initialValue={rating.performance}
                        SVGclassName='inline-block'
                        size={30}
                    />
                </div>
                <div className="grid grid-cols-2 items-center">
                    <p className='text-left'>Behaviour</p>
                    <Rating
                        onClick={(rate) => {
                            updateRating({behaviour: rate})
                            setRating((prevState) => ({
                                ...prevState,
                                behaviour: rate
                            }))
                        }}
                        readonly={!isAdmin ? !updateEmployee : !isAdmin}
                        allowHover={isAdmin ? isAdmin : updateEmployee}
                        initialValue={rating.behaviour}
                        SVGclassName='inline-block'
                        size={30}
                    />
                </div>
                <div className="grid grid-cols-2 items-center">
                    <p className='text-left'>Team Bonding</p>
                    <Rating
                        onClick={(rate) => {
                            updateRating({teamBonding: rate})
                            setRating((prevState) => ({
                                ...prevState,
                                teamBonding: rate
                            }))
                        }}
                        readonly={!isAdmin ? !updateEmployee : !isAdmin}
                        allowHover={isAdmin ? isAdmin : updateEmployee}
                        initialValue={rating.teamBonding}
                        SVGclassName='inline-block'
                        size={30}
                    />
                </div>
                <p className='text-[#aaa] text-[12px] mt-[20px]'>Note: These ratings are generated manually or according to performance given by your Head. Contact your Head/HR if you have any issues with the same.</p>
            </div>
            <div className="mt-[50px] w-full">
                <h6>Previous Ratings (Quarterly)</h6>
                <div className='mt-[30px] w-full overflow-auto scrollbar-h-5px'>
                    {allRatings?.length > 0 ? (
                        <table className='w-full'>
                            <thead>
                                <tr className='bg-[#dcdcdc] py-[120px]'>
                                    <td
                                        style={{ width: '5%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Year</td>
                                    <td
                                        style={{ width: '20%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Months</td>
                                    <td 
                                        style={{ width: '15%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Performance</td>
                                    <td 
                                        style={{ width: '15%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Behaviour</td>
                                    <td 
                                        style={{ width: '15%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Team Bonding</td>
                                    <td 
                                        style={{ width: '15%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Total</td>
                                    <td 
                                        style={{ width: '15%' }}
                                        className='!font-bold !text-[14px] text-left px-[10px] py-[10px]'
                                    >Award</td>
                                </tr>
                            </thead>
                            <tbody>
                                {allRatings.map((item, i) => (
                                    <tr key={i} className={`bg-[#f8f8ff] border-b`}>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            {item.year}
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            {item.months.map(index => index + " ")}
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            <Rating
                                                readonly
                                                allowHover={false}
                                                initialValue={item.performance}
                                                SVGclassName='inline-block'
                                                size={20}
                                            />
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            <Rating
                                                readonly
                                                allowHover={false}
                                                initialValue={item.behaviour}
                                                SVGclassName='inline-block'
                                                size={20}
                                            />
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            <Rating
                                                readonly
                                                allowHover={false}
                                                initialValue={item.teamBonding}
                                                SVGclassName='inline-block'
                                                size={20}
                                            />
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize'>
                                            <Rating
                                                readonly
                                                allowHover={false}
                                                initialValue={getTotalRating(item)}
                                                SVGclassName='inline-block'
                                                size={20}
                                            />
                                        </td>
                                        <td className='text-left text-[14px] px-[10px] py-[5px] capitalize' style={{color: getTotalRatingLabel(item).color}}>
                                            {getTotalRatingLabel(item).label}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ):(
                        <div className='w-full text-left text-[12px] mt-[30px]'>No Ratings yet!</div>
                    )}
                </div>
            </div>
        </div>
    )
}

const performanceLabel = [
    {
        range: [0, 1],
        color: '#FF0000',
        label: 'Very Poor'
    },
    {
        range: [1.1, 2.4],
        color: '#FFA500',
        label: 'Poor'
    },
    {
        range: [2.5, 3.4],
        color: '#efcc00',
        label: 'Average'
    },
    {
        range: [3.5, 4.4],
        color: '#90EE90',
        label: 'Good'
    },
    {
        range: [4.5, 5],
        color: '#008000',
        label: 'Excellent'
    }
];

export default Ratings;