import React from 'react'
import isBirthdayToday from '../../utils/isBirthdayToday';
import Avatar from '../custom/avatar';
import { HiLocationMarker } from "react-icons/hi";
import { Link } from 'react-router-dom';

const EmployeeCard = ({data, key}) => {
    return (
        <Link to={`/employee/${data._id}`} key={key} className="group relative w-full bg-white border border-gray-200 rounded-lg shadow p-[20px] flex gap-[30px] items-start overflow-hidden">
            {isBirthdayToday(data.birthday) && (
                <BirthdayTag />
            )}
            <Avatar
                src={data
                    ? data?.blockedBy?.includes(data?._id)
                        ? ""
                        : data?.settings
                            ? data?.settings.showAvatar
                                ? data?.avatar
                                : ""
                            : data?.avatar
                    : ""
                }
                alt={data.fullName} 
                size={20} 
                className={`h-[50px] min-w-[50px] border-[2px] ${isBirthdayToday(data.birthday) ? "!border-error" : data.gender === "female" ? "!border-[#FF69B4] border-[2px]" : data.gender === "male" ? "!border-[#6ca0dc]" : "border-[#aaa]"}`} 
                noOnline
            />
            <div>
                <p className="text-[#aaa] break-all">{data.employeeID}</p>
                <h6 className="font-medium capitalize">{data.fullName}</h6>
                <p className="text-black break-all">{data.email}</p>
                <p className="text-[#aaa] capitalize">{data.role?.departmentId?.title} ({data.role?.title})</p>
                {data.officeLocation && (
                    <p className="text-black break-all flex items-center capitalize"><HiLocationMarker className='text-error' /> {data.officeLocation}</p>
                )}
            </div>
        </Link>
    )
}

function BirthdayTag(){
    return(
        <div className='text-error -rotate-[40deg] bg-[#ffe4e1] px-[21px] absolute top-[10px] left-[-20px] z-[1] shadow-md'>
            Birthday
        </div>
    )
}

export default EmployeeCard;