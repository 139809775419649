import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allLogs: [],
    pageCount: 1,
    totalLogsCount: 0
}

const logSlice = createSlice({
    name: 'log',
    initialState,
    reducers: {
        setAllLogs(state, action) {
            state.allLogs = action.payload;
        },
        setPageCount(state, action) {
            state.pageCount = action.payload;
        },
        setTotalLogsCount(state, action) {
            state.totalLogsCount = action.payload;
        },
    }
});

export const logActions = logSlice.actions;

export default logSlice.reducer;