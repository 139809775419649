import React, { useEffect, useState } from 'react'
import { DeleteRequest, GetRequest, PostRequest } from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { MdDelete } from 'react-icons/md';
import { formatDateForCompare, isTimeout, timestampLabel } from '../../utils/formatTimestamp';
import Avatar from '../custom/avatar';
import IconButton from '../custom/iconButton';
import TaskSocket from "../../socket/task-socket";
import RichText from '../custom/richText';
import { taskActions } from '../../store/task-slice';
import DeleteConfirmation from '../../popups/deleteConfirmation';
import { uiActions } from '../../store/ui-slice';

const Activities = () => {
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { activeProject } = useSelector((state) => state.project);
    const { activities, selectedTaskData, activityTrigger, assignees } = useSelector((state) => state.task);

    const [message, setMessage] = useState("");

    useEffect(() => {
        if (selectedTaskData) {
            GetRequest(`${process.env.REACT_APP_URL}/activity/${selectedTaskData._id}`).then(response => {
                dispatch(taskActions.setActivities(response.data))
            }).catch(error => {
                console.log("getting activity error", error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activityTrigger, selectedTaskData])

    // ADDS COMMENT ON THE TASK
    function commentHandler() {
        PostRequest(`${process.env.REACT_APP_URL}/activity/${selectedTaskData._id}`, {
            comment: message,
            userId: accountInfo?.data?._id
        }).then(response => {
            TaskSocket.emitTaskUpdate(activeProject?._id, response.data, assignees, "comment added")
            setMessage("")
            dispatch(taskActions.setActivityTrigger(activityTrigger + 1))
        }).catch(error => {
            dispatch(uiActions.setToastify({
                isSuccess: false,
                message: "Error adding comment"
            }))
        });
    }

    // DELETE COMMENT ON THE TASK
    function deleteActivityHandler(id) {
        DeleteRequest(`${process.env.REACT_APP_URL}/activity/${id}`).then(response => {
            TaskSocket.emitTaskUpdate(activeProject?._id, selectedTaskData?._id, assignees, "comment deleted")
            dispatch(taskActions.setActivityTrigger(activityTrigger + 1))
        }).catch(error => {
            console.log("delete activity error >>>", error.data);
        });
    }

    const removeEmptyTags = (input) => {
        const emptyTags = ['<p><br></p>', '<p><br/></p>', '<p><br /></p>'];
        let cleanedInput = input;
        emptyTags.forEach(tag => {
            cleanedInput = cleanedInput.replace(tag, '');
        });
        return cleanedInput;
    };

    function localDateTime(isoString) {
        const date = new Date(isoString);

        if (isNaN(date.getTime())) {
            console.error("Invalid ISO string:", isoString);
            return '';
        }

        // Get the local timezone offset
        const offset = date.getTimezoneOffset() * 60000;
        // Adjust UTC to local time
        return new Date(date.getTime() - offset).toISOString().slice(0, 16); // Returns 'YYYY-MM-DDTHH:mm'
    }

    function formatComment(comment) {
        const regex = /<b>(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)<\/b>/; // Match UTC format
        const match = comment.match(regex);

        if (match && match[1]) {
            const utcDateStr = match[1]; // Extract the UTC date string

            try {
                // Convert UTC to local ISO format
                const formattedDate = localDateTime(utcDateStr);

                if (formattedDate) {
                    // Replace UTC with formatted local time in the comment
                    return comment.replace(regex, `<b>${formattedDate.replace('T', ' ')}</b>`);
                }
            } catch (error) {
                console.error('Error formatting date:', error);
            }
        }

        // Return the original comment if no valid date is found
        return comment;
    }

    return (
        <div className='relative bg-white mt-[50px]'>
            <h5 className='lg:px-[5px]'>Activity</h5>
            <div className='mt-[20px] grid gap-[10px] h-full overflow-hidden overflow-y-auto'>
                <div className='overflow-hidden overflow-y-auto pb-[15px] min-h-[500px] max-h-[500px] flex flex-col gap-[10px] scroll-it'>
                    {/* Task creation log */}
                    <div className='text-[12px] flex justify-between items-start py-[10px] text-[#808080] px-[10px] gap-[10px]'>
                        <div className='max-w-[75%] flex'>
                            ~ <p className='text-[12px] activity-richtext ml-[5px]'> Task created by {selectedTaskData?.createdBy?.fullName}</p>
                        </div>
                        <span className='text-[12px]'>{formatDateForCompare(selectedTaskData?.createdAt)}</span>
                    </div>

                    {/* Activity logs */}
                    {activities?.length > 0 &&
                        activities.map((item, i) => (
                            item.isComment ? (
                                <div key={i} className='group border rounded-[5px] bg-[#fff]'>
                                    {/* User comment log */}
                                    <div className='flex items-center justify-between border-b p-[10px]'>
                                        <div className='flex items-center gap-[10px]'>
                                            <Avatar
                                                src={item?.userId?.avatar}
                                                alt="user"
                                                className='h-[25px] w-[25px] min-w-[25px]'
                                                noOnline
                                            />
                                            <h6 className='text-[14px]'>{item?.userId?.fullName}</h6>
                                        </div>
                                        {accountInfo?.data._id === item?.userId?._id && (
                                            <DeleteConfirmation
                                                onClick={() => deleteActivityHandler(item._id)}
                                                description="Are you sure you want to delete this comment?"
                                                component={
                                                    <IconButton
                                                        icon={<MdDelete size={15} color='#000' />}
                                                        className='border-none group-hover:flex hidden h-[20px] w-[20px]'
                                                    />
                                                }
                                                className='border-none rounded-none'
                                            />
                                        )}
                                        {isTimeout(item.createdAt) && (
                                            <span className={`text-[12px] ${accountInfo?.data._id !== item?.userId?._id ? "block" : "group-hover:hidden block"}`}>{timestampLabel(item.createdAt)}</span>
                                        )}
                                    </div>
                                    <div className='text-[12px] p-[10px] richtext whitespace-break-spaces break-words' dangerouslySetInnerHTML={{ __html: item.comment }} />
                                </div>
                            ) : (
                                <div key={i} className='text-[12px] flex justify-between items-start py-[10px] text-[#808080] px-[10px] gap-[10px]'>

                                    <div className="max-w-[75%] flex">
                                        <p
                                            className="text-[12px] activity-richtext ml-[5px]"
                                            dangerouslySetInnerHTML={{
                                                __html: formatComment(item.comment),
                                            }}
                                        />
                                    </div>
                                    <span className='text-[12px]'>{timestampLabel(item.createdAt)}</span>
                                </div>
                            )
                        ))}
                </div>
                {/* Comment input */}
                <div className='activity-chat-msg flex gap-[5px] w-full mt-[10px]'>
                    <RichText
                        value={message}
                        onChange={(input) => setMessage(removeEmptyTags(input))}
                        placeholder="Write a comment"
                        className='w-full comment-richtext bg-white overflow-hidden'
                    />
                    <div className='flex items-end'>
                        <button
                            onClick={commentHandler}
                            className={`text-white px-[10px] py-[14.5px] text-[14px] rounded-[5px] ${message ? "pointer-events-auto bg-black" : "pointer-events-none bg-[#555555]"}`}
                        >
                            Comment
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );



}

export default Activities;