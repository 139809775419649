import React from 'react'

const BlockedMessage = ({ blockedByMe }) => {
    return (
        <div className='fixed right-0 bottom-0 bg-[#F5FFFA] w-full lg:pl-[250px] py-[20px]'>
            {blockedByMe ?
                <p className='text-[#aaa] text-center'>You’ve blocked this person! Your replies are now traveling through an interdimensional void. 🚀🌌</p>
                :
                <p className='text-[#aaa] text-center'>Oops! You can not reply to this conversation :( </p>
            }
        </div>
    )
};

export default BlockedMessage;