import React, { useEffect, useState } from 'react';
import ReactModal from '.';
import TextInput from '../components/custom/textInput';
import Label from '../components/custom/label';
import Dropdown from '../components/custom/dropdown';
import Gender from '../components/shared/gender';
import { checkEmptyFields, validateEmail, validatePhone } from '../utils/formValidation';
import Error from '../components/custom/error';
import { GetRequest, PutRequest } from '../utils/request';
import { useSelector, useDispatch } from 'react-redux';
import { employeeActions } from '../store/employee-slice';
import Button from '../components/custom/button';
import Loader from '../components/custom/loader';
import { formatDateForCompare } from '../utils/formatTimestamp';

const UpdateEmployee = ({ triggerEmployee=()=>{ }, departments = [] }) => {
    const dispatch = useDispatch();
    const { updateEmployee } = useSelector((state) => state.employee);
    const { accountInfo } = useSelector((state) => state.account);

    const [error, setError] = useState("");
    const [roles, setRoles] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [selectedRole, setSelectedRole] = useState(updateEmployee?.role || null);

    const [formInput, setFormInput] = useState({
        fullName: updateEmployee?.fullName,
        email: updateEmployee?.email,
        gender: updateEmployee?.gender,
        role: updateEmployee?.role?._id,
        birthday: updateEmployee?.birthday ? formatDateForCompare(updateEmployee.birthday) : "",
        joiningDate: updateEmployee?.joiningDate ? formatDateForCompare(updateEmployee.joiningDate) : "",
        employeeID: updateEmployee?.employeeID || "",
        officeLocation: updateEmployee?.officeLocation || "",
        mobile: updateEmployee?.mobile || "",
    });

    useEffect(() => {
        setSelectedDepartment(departments.find(index => index._id === updateEmployee?.role?.departmentId?._id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departments])

    useEffect(() => {
        if (accountInfo) {
            GetRequest(`${process.env.REACT_APP_URL}/department/${accountInfo.data.adminId || accountInfo.data._id}`).then(response => {
                dispatch(employeeActions.setDepartments(response.data));
            }).catch((error) => {
                console.log("department error >", error);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountInfo])



    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "birthday" || name === "joiningDate") {
            const [year, month, day] = value.split("-");
            if (year && year.length > 4) {
                return;
            }
            if ((month && month.length > 2) || (day && day.length > 2)) {
                return;
            }
        }
        setFormInput((prevState) => ({
            ...prevState,
            [name]: value?.trim()
        }));
        setError("");
    };

    const idHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const nameHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(/[^A-Za-z\s]/g, '');
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    const emailHandler = (e) => {
        const { name, value } = e.target;
        const cleanedValue = value.replace(" ", "")?.toLowerCase();
        setFormInput((prevState) => ({
            ...prevState,
            [name]: cleanedValue
        }));
        setError("");
    };

    //PHONE NUMBER INPUT HANDLER
    const phoneHandler = (e) => {
        const { name, value } = e.target
        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length <= 10) {
            setFormInput((prevState) => ({ ...prevState, [name]: numericValue }));
            setError("");
        }
    };
    useEffect(() => {
        if (selectedDepartment && selectedDepartment._id) {
            GetRequest(`${process.env.REACT_APP_URL}/department/roles/${selectedDepartment._id}`).then(response => {
                setRoles(response.data);
                const role = response.data.length > 0 && response.data.find(index => index._id === updateEmployee?.role?._id)
                setSelectedRole(role);
                triggerEmployee()
            }).catch((error) => {
                console.error("roles error >", error);
                setRoles([]);
            });
        } else {
            setRoles([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDepartment]);

    const updateHandler = () => {
        setDisabled(true)
        setError("")
        const impFields = {
            fullName: formInput?.fullName,
            email: formInput?.email,
            gender: formInput?.gender,
            role: formInput?.role,
            birthday: formInput?.birthday,
            joiningDate: formInput?.joiningDate,
            mobile: formInput?.mobile
        }
        if (checkEmptyFields(impFields)) {
            setError("*Fields must not be empty!")
            setDisabled(false)
        } else if (!validateEmail(formInput.email)) {
            setError("Invalid Email!")
            setDisabled(false)
        } else if (!validatePhone(formInput.mobile)) {
            setError("Invalid Phone!")
            setDisabled(false)
        } else {

            const formData = new FormData();
            if (formInput?.fullName?.toLowerCase()?.trim() !== updateEmployee?.fullName?.toLowerCase()) {
                formData.append('fullName', formInput?.fullName?.trim());
            }
            if (formInput?.email?.toLowerCase()?.trim() !== updateEmployee?.email?.toLowerCase()) {
                formData.append('email', formInput?.email?.trim());
            }
            if (formInput?.gender !== updateEmployee?.gender) {
                formData.append('gender', formInput?.gender);
            }
            if (formInput?.employeeID?.trim() !== updateEmployee?.employeeID) {
                formData.append('employeeID', formInput?.employeeID?.trim());
            }
            if (formInput?.officeLocation?.trim() !== updateEmployee?.officeLocation) {
                formData.append('officeLocation', formInput?.officeLocation?.trim()?.toLowerCase());
            }
            if (formInput?.birthday !== updateEmployee?.birthday) {
                formData.append('birthday', formInput?.birthday);
            }
            if (formInput?.joiningDate !== updateEmployee?.joiningDate) {
                formData.append('joiningDate', formInput?.joiningDate);
            }
            if (formInput?.role !== updateEmployee?.role) {
                formData.append('role', formInput?.role);
            }
            if (formInput?.mobile !== updateEmployee?.mobile) {
                formData.append('mobile', formInput?.mobile);
            }
            PutRequest(`${process.env.REACT_APP_URL}/user/${updateEmployee?._id}?adminId=${accountInfo?.data?.adminId || accountInfo?.data._id}`, formData).then((response) => {
                setDisabled(false)
                triggerEmployee()
                dispatch(employeeActions.setUpdateEmployee(null))
            }).catch((error) => {
                console.log(error)
                setError(error.data)
                setDisabled(false)
            })
        }
    }

    const handleClose = () => {
        setFormInput({
            fullName: "",
            email: "",
            password: "",
            gender: "prefer not to say",
            department: "",
            role: "",
        })
        dispatch(employeeActions.setUpdateEmployee(null))
        setError("")
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            event.preventDefault();
            updateHandler()
        }
    };
    // console.log(">role", role !== "admin" || !permissions?.updateEmployee, role !== "admin", !permissions?.updateEmployee)
    return (
        <ReactModal open={updateEmployee} close={handleClose} maxWidth="600px" heading="Update Employee">
            {disabled && (
                <Loader />
            )}
            {error && <Error message={error} />}
            <div className='grid grid-cols-1 md:grid-cols-2 gap-x-[20px] gap-y-[10px]'>
                <div>
                    <Label title='Employee ID' />
                    <TextInput
                        name="employeeID"
                        value={formInput.employeeID}
                        onChange={idHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                        maxLength={30}
                    />
                </div>
                <div>
                    <Label title='Full Name' isImportant />
                    <TextInput
                        name="fullName"
                        value={formInput.fullName}
                        onChange={nameHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Email' isImportant />
                    <TextInput
                        name="email"
                        value={formInput.email}
                        onChange={emailHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Mobile' isImportant />
                    <TextInput
                        name="mobile"
                        value={formInput.mobile}
                        onChange={phoneHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Birthday' isImportant />
                    <TextInput
                        name="birthday"
                        type='date'
                        value={formInput.birthday}
                        onChange={handleChange}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Department' isImportant />
                    <Dropdown
                        value={selectedDepartment ? selectedDepartment.title : ""}
                        onChange={(option) => {
                            setSelectedDepartment(option);
                            setFormInput((prevState) => ({
                                ...prevState,
                                department: option._id
                            }));
                        }}
                        options={departments}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Designation' isImportant />
                    <Dropdown
                        value={selectedRole ? selectedRole.title : ""}
                        onChange={(option) => {
                            setSelectedRole(option);
                            setFormInput((prevState) => ({
                                ...prevState,
                                role: option._id
                            }))
                        }}
                        options={roles}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Date of Joining' isImportant />
                    <TextInput
                        name="joiningDate"
                        type='date'
                        value={formInput.joiningDate}
                        onChange={handleChange}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                    />
                </div>
                <div>
                    <Label title='Office Location' />
                    <TextInput
                        name="officeLocation"
                        value={formInput.officeLocation}
                        onChange={nameHandler}
                        className='mt-[5px]'
                        onKeyDown={handleKeyDown}
                        maxLength={50}
                    />
                </div>
                <div>
                    <Label title='Gender' isImportant />
                    <Gender
                        value={formInput.gender}
                        onClick={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </div>
            <div className='flex justify-center gap-[10px] mt-[40px]'>
                <Button
                    label='Update'
                    onClick={updateHandler}
                    type='secondary'
                />
            </div>
        </ReactModal>
    );
};

export default UpdateEmployee;