import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ChatSocket from "../../socket/chat-socket";
import { GetRequest } from '../../utils/request';
import Layout from '../../layout';
import Loader from '../../components/custom/loader';
import StickyNote from '../../components/notes/stickyNote';
import CreateNote from '../../popups/createNote';
import { noteActions } from '../../store/note-slice';
import socket from '../../socket';
import Pagination from '../../components/custom/pagination';

const Notes = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { accountInfo } = useSelector((state) => state.account);
    const { chatTheme } = useSelector(state => state.ui);
    const { allNotes, trigger, pageCount, totalNotesCount } = useSelector((state) => state.note);

    const [receivedNote, setReceivedNote] = useState([]);
    const [indicator, setIndicator] = useState(false)
    ChatSocket.useMessageEvent(setReceivedNote, setIndicator, receivedNote, indicator);
    ChatSocket.useMyMessageEvent(setReceivedNote);
    ChatSocket.useFriendChatEvent();
    ChatSocket.useRemoveMessageEvent();

    const getNotes = (page) => {
        GetRequest(`${process.env.REACT_APP_URL}/notes?id=${accountInfo?.data?._id}&page=${page}&limit=9`).then(response => {
            dispatch(noteActions.setTotalNotesCount(response.data.notesCount))
            if (response.data.notes.length === 0) {
            }
            if (page === 1) {
                dispatch(noteActions.setAllNotes(response.data.notes))
                setReceivedNote([])

            } else if (response.data.notes.length > 0) {
                dispatch(noteActions.setAllNotes(response.data.notes))
            }
            setLoading(false)
        }).catch(error => {
            console.log("note fetching error >>>", error);
        });
    };

    useEffect(() => {
        if (pageCount === 1) {
            socket.on('notesUpdated', (updatedNote) => {
                const updatedNotes = [...allNotes];
                updatedNotes.pop();
                dispatch(noteActions.setAllNotes(updatedNotes))
                dispatch(noteActions.setAllNotes([updatedNote, ...updatedNotes]))
            });
            return () => {
                socket.off('notesUpdated');
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allNotes]);

    useEffect(() => {
        if (accountInfo) {
            getNotes(pageCount);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger, accountInfo]);

    return (
        <Layout bgClass={chatTheme.className}>
            {loading && (
                <Loader className='bg-[#F5FFFA]' loaderCss="text-[#000]" />
            )}
            <div
                // ref={noteRef}
                // onScroll={onScroll}
                className='w-full h-[calc(100vh_-_70px)] pt-[20px] md:px-[10px] flex-end'
            >
                <div className='pt-[20px] pb-[50px] mb-[30px]'>
                    <div className='flex flex-wrap justify-between gap-[20px] mb-[30px]'>
                        <h2>Sticky Notes</h2>
                        <CreateNote />
                    </div>
                    {allNotes?.length > 0 ?
                        <Pagination
                            itemsPerPage={9}
                            data={allNotes}
                            type='box'
                            position='center'
                            renderComponent={(currentItems) => (
                                <NotesData data={currentItems} />
                            )}
                            onPageChange={getNotes}
                            totalCount={totalNotesCount}
                        />
                        : (
                            <div className='py-[100px]'>
                                <p className='text-center'>No Notes added yet!</p>
                            </div>
                        )}
                </div>
            </div>
        </Layout>
    )
}

function NotesData({ data }) {
    return (
        <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[20px] gap-[20px] overflow-hidden overflow-y-auto mb-[100px] '>
            {data?.map((item, i) => (
                <StickyNote
                    key={i}
                    {...item}
                />
            ))}
        </div>
    )

}

export default Notes;