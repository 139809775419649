import React from 'react'
import Button from '../custom/button'
import { DeleteRequest, PutRequest } from '../../utils/request'
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from '../../popups/deleteConfirmation';
import { useNavigate } from 'react-router-dom';
import { uiActions } from '../../store/ui-slice';

const Account = ({ triggerEmployee=()=>{} }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { accountInfo } = useSelector((state) => state.account);
    const { employeeData } = useSelector((state) => state.employee);
    const isActive = employeeData?.userData?.isActive || false;

    function deactivateHandler(){
        PutRequest(`${process.env.REACT_APP_URL}/user/${employeeData?.userData?._id}?adminId=${accountInfo?.data._id}`, { isActive: !isActive }).then((response) => {
            dispatch(uiActions.setToastify({
                isSuccess: true,
                message: `Employee ${isActive ? "deactivated" : "activated"} successfully!`
            }))
            triggerEmployee()
        }).catch((error) => {
            console.log(error)
        })
    }
    function deleteHandler() {
        DeleteRequest(`${process.env.REACT_APP_URL}/user/${employeeData?.userData?._id}?userId=${accountInfo?.data?._id}`).then(response => {
            dispatch(uiActions.setToastify({
                isSuccess: true,
                message: "Employee deleted successfully!"
            }))
            navigate("/employees")
        }).catch(error => {
            console.log("delete error", error)
        })
    }
    return (
        <div className="bg-white p-[30px] my-[50px] max-w-[600px] mx-auto">
            <DeleteConfirmation
                onClick={deleteHandler}
                className='w-[250px] mx-auto bg-strawberry'
                component={
                    <div className='text-white'>
                        Delete
                    </div>
                }
                // disabled={role !== "admin"}
            />
            <p className='text-[#aaa] my-[10px] text-center'>Note: It will delete all the data related to this account! Maybe! ;)</p>
            <p className='text-[#aaa] my-[10px] text-center'>-------- or --------</p>
            <Button 
                label={isActive ? "Deactivate" : "Activate"}
                className='max-w-[250px] mx-auto'
                type='secondary'
                onClick={deactivateHandler}
            />
            <p className='text-[#aaa] my-[10px] text-center'>Note: It will make the account either active or inactive! Hope so!</p>
        </div>
    )
}

export default Account