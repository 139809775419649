import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    employeeData: null,
    employees: [],
    filteredResults: [],
    departments: [],
    roles: [],
    selectedDepartment: null,
    selectedRole: null,
    searchKey: "",
    updateEmployee: null,
    viewEmployee: null,
    enquiryList: [],
    employeeTrigger: 0,
    allRatings: [],
    pageCount: 1,
    totalEmployeesCount: 0,
    totalDeptCount: 0

}

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployeeData(state, action) {
            state.employeeData = action.payload;
        },
        setEmployees(state, action) {
            state.employees = action.payload;
        },
        setFilteredResults(state, action) {
            state.filteredResults = action.payload;
        },
        setDepartments(state, action) {
            state.departments = action.payload;
        },
        setRoles(state, action) {
            state.roles = action.payload;
        },
        setSelectedDepartment(state, action) {
            state.selectedDepartment = action.payload;
        },
        setSelectedRole(state, action) {
            state.selectedRole = action.payload;
        },
        setSearchKey(state, action) {
            state.searchKey = action.payload;
        },
        setUpdateEmployee(state, action) {
            state.updateEmployee = action.payload;
        },
        setViewEmployee(state, action) {
            state.viewEmployee = action.payload;
        },
        setEnquiryList(state, action) {
            state.enquiryList = action.payload;
        },
        setEmployeeTrigger(state, action) {
            state.employeeTrigger = action.payload;
        },
        setAllRatings(state, action) {
            state.allRatings = action.payload;
        },
        setPageCount(state, action) {
            state.pageCount = action.payload;
        },
        setTotalEmployeesCount(state, action) {
            state.totalEmployeesCount = action.payload;
        },
        setTotalDeptCount(state, action) {
            state.totalDeptCount = action.payload;
        },
        
    }
});

export const employeeActions = employeeSlice.actions;

export default employeeSlice.reducer;